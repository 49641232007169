import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData } = useContext(BusinessDataContext);  

  document.title = "Data Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({ 
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (smePlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'id', value: smePlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: smePlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'amount', value: smePlan.amount },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'share_limit', value: smePlan.share_limit },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'data_bundle', value: smePlan.data_bundle },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: smePlan.status },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("share_limit", String(formData.share_limit));
    urlencoded.append("data_bundle", String(formData.data_bundle));
    urlencoded.append("status", String(formData.status));
     
    var requestOptions = {
      method: formData.id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
    formData.id ?  localStorage.getItem("apiURL") + "mtn_sme/"+formData.id  : localStorage.getItem("apiURL") + "mtn_sme"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        formData.id ? tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "smePlans", value:tempData.smePlans.map((smePlan) => {
            if(data.data.id===smePlan.id) return {...smePlan, ...data.data}
            return smePlan;
           } 
         )}
          }) : 
          fetchDataPlans(1);

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.smePlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'smePlansLoaded', value: true },
      });
    }
  }, []);
    
  const filterNetwork =(e)=>{
    var smePlan=tempData.smePlansMaster;
   if(e.target.value)
    smePlan=smePlan.filter(smePlan => smePlan.network=== e.target.value)
   
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "smePlans", 
      value: smePlan
    }});
  }

   
    
  const SmePlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.smePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.smePlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
     
             <td   >{props.smePlan.name}</td>
             <td   >₦ {formatCurrency(props.smePlan.amount)}</td>
             <td   >{props.smePlan.share_limit}</td>
              <td   >{formatCurrency(props.smePlan.data_bundle)}MB</td>
              <td   >{props.smePlan.id}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.smePlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving data servers...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "mtn_sme" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "smePlans", value: data.data },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "smePlansMaster", value: data.data },
                    });
                  
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>MTN SME PLANS</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,phone_number:'', password:'',network:'MTN'})
                }}><i className="mdi mdi-plus"></i> Add MTN SME Plan</b></p>
                <hr className="border-primary" />
                </div>
            
                  {tempData.smePlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.smePlans.length} MTN SME Plans</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                     
                      <th data-priority="1">Status</th>
                      <th data-priority="1">Plan Name</th>
                       <th data-priority="1">Amount</th>
                       <th data-priority="1">Shares Limit</th>
                       <th data-priority="1">Data Bundle</th>
                       <th data-priority="1">ID</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.smePlans.map((smePlan) => {
                        return <SmePlan key={smePlan.id} smePlan={smePlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.smePlans && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>{formData.id ? 'Edit SME Plan': 'Add SME Plan'}</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

 <AvField
      label="Name"
      name="name"
      value={formData.name}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Phone Name"
      type="text"
      errorMessage="Enter valid name"
      required
      />
               
      <AvField
      label="Amount"
      name="amount"
      value={formData.amount}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Plan recharge amount"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

<AvField
      label="Shares Limit"
      name="share_limit"
      value={formData.share_limit}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Plan shares "
      type="text"
      errorMessage="Enter valid amount"
      required
      />



<AvField
      label="Data Bundle (in MB)"
      name="data_bundle"
      value={formData.data_bundle}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Data Bundles"
      type="text"
      errorMessage="Enter valid amount"
      required
      />
 
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.id ? 'Save Changes': 'Add SME Plan'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        