import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  if (!props.summary) document.title = "Accounts Summary -DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const DateTag = () => {
    return (
      <>
        <Col md="5" xs="5" className="form-group mb-2">
          <input
            type="date"
            className="form-control"
            name="dateFrom"
            value={formData.dateFrom}
            onChange={formOnChange}
          />

          <input
            type="time"
            className="form-control"
            name="timeFrom"
            value={formData.timeFrom}
            onChange={formOnChange}
          />
        </Col>
        <label className="mt-2">To</label>
        <Col md="5" xs="5" className="form-group mb-2">
          <input
            type="date"
            className="form-control"
            name="dateTo"
            value={formData.dateTo}
            onChange={formOnChange}
          />

          <input
            type="time"
            className="form-control"
            name="timeTo"
            value={formData.timeTo}
            onChange={formOnChange}
          />
        </Col>
      </>
    );
  };

  const Businesses = () => {
    return (
      <>
        <Col md="6" xs="6" className="form-group mb-2">
          <select
            className="form-control"
            value={formData.business_id}
            name="business_id"
            onChange={formOnChange}
          >
            <option key="-1" value="">
              All Businesses
            </option>
            {businessData.businesses.map((business, index) => {
              return (
                <option key={index} value={business.business_id}>
                  {business.name}
                </option>
              );
            })}
          </select>
        </Col>
      </>
    );
  };

  const Network = () => {
    return (
      <>
        <Col md="6" xs="6" className="form-group mb-2">
          <select
            className="form-control"
            value={formData.network}
            name="network"
            onChange={formOnChange}
          >
            <option value="">All Networks</option>
            <option value="MTN">MTN</option>
            <option value="GLO">GLO</option>
            <option value="AIRTEL">AIRTEL</option>
            <option value="ETISALAT">ETISALAT</option>
          </select>
        </Col>
      </>
    );
  };

  const handleSubmit = (e, tableName) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(tableName);
  };

  const fetchTransactions = (tableName) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving statistics...", isLoading: true },
    });
    formDataDispatch({
      type: "SET_ERROR",
      data: "",
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "&table=" + tableName;

    parameter += formData["business_id"]
      ? "&business_id=" + formData["business_id"]
      : "";
    parameter += formData["transaction_type"]
      ? "&transaction_type=" + formData["transaction_type"]
      : "";
    parameter += formData["stock_type"]
      ? "&stock_type=" + formData["stock_type"]
      : "";
    parameter += formData["network"] ? "&network=" + formData["network"] : "";
    parameter += formData["disco"] ? "&disco=" + formData["disco"] : "";
    parameter += formData["tv"] ? "&tv=" + formData["tv"] : "";
    parameter += formData["payment_method"]
      ? "&payment_method=" + formData["payment_method"]
      : "";
    parameter += formData["status"] ? "&status=" + formData["status"] : "";
    parameter += formData["type"] ? "&type=" + formData["type"] : "";
    parameter += formData["description"]
      ? "&description=" + formData["description"]
      : "";
    parameter += formData["operation"]
      ? "&operation=" + formData["operation"]
      : "";

    parameter += formData.dateFrom
      ? "&date_from=" +
        formData.dateFrom +
        " " +
        (formData.timeFrom && formData.timeFrom)
      : "";
    parameter += formData.dateTo
      ? "&date_to=" +
        formData.dateTo +
        " " +
        (formData.timeTo && formData.timeTo)
      : "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "accounts?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: tableName + "_count", value: data.data.count },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: tableName + "_amount", value: data.data.amount },
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: tableName + "_commission",
              value: data.data.commission,
            },
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: tableName + "_airtime_value",
              value: data.data.airtime_value,
            },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: tableName + "_data_expended",
              value: data.data.data_expended,
            },
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: tableName + "_airtime_expended",
              value: data.data.airtime_expended,
            },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center  align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow  ">
              <CardBody className=" ">
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Accounts Summary</b>
                  </p>
                  <hr className="border-primary" />
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}
                </div>

                <Row className="pl-2">
                  {/* ######################################################### */}
                  <Col xs="5" className="shadow m-1 p-1">
                    <p>
                      <b>Airtime Subscriptions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "airtime_subscriptions")
                      }
                    >
                      <Row>
                        <Businesses />
                        <Network />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.payment_method}
                            name="payment_method"
                            onChange={formOnChange}
                          >
                            <option value="">All Payment Methods</option>
                            <option value="0">ATM</option>
                            <option value="1">Wallet</option>
                            <option value="2">Admin Purchase</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Delivered</option>
                            <option value="1">Paying</option>
                            <option value="2">Charging Merchant</option>
                            <option value="3">Queued</option>
                            <option value="4">Processing</option>
                            <option value="5">Reversed</option>
                            <option value="6">Failed</option>
                            <option value="7">Cancelled</option>
                          </select>
                        </Col>
                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.airtime_subscriptions_count && (
                          <p>
                            Transactions Count:{" "}
                            {tempData.airtime_subscriptions_count}
                          </p>
                        )}
                        {tempData.airtime_subscriptions_amount && (
                          <p>
                            Amount Paid by Customer: ₦
                            {formatCurrency(
                              tempData.airtime_subscriptions_amount
                            )}
                          </p>
                        )}{" "}
                        {tempData.airtime_subscriptions_amount && (
                          <p>
                            Commission paid to merchants: ₦
                            {formatCurrency(
                              tempData.airtime_subscriptions_commission
                            )}
                          </p>
                        )}
                        {tempData.airtime_subscriptions_amount && (
                          <p>
                            Amount Settled to DataNow: ₦
                            {formatCurrency(
                              tempData.airtime_subscriptions_amount -
                                tempData.airtime_subscriptions_commission
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow m-1 p-1">
                    <p>
                      <b>Data Subscriptions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "data_subscriptions")
                      }
                    >
                      <Row>
                        <Col md="6" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.business_id}
                            name="business_id"
                            onChange={formOnChange}
                          >
                            <option key="-1" value="">
                              All Businesses
                            </option>
                            {businessData.businesses.map((business, index) => {
                              return (
                                <option
                                  key={index}
                                  value={business.business_id}
                                >
                                  {business.name}
                                </option>
                              );
                            })}
                          </select>
                        </Col>

                        <Col md="6" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.type}
                            name="type"
                            onChange={formOnChange}
                          >
                            <option key="-1" value="">
                              All Data Types
                            </option>
                            <option value="0">SME Data</option>
                            <option value="1">Normal Data</option>
                            <option value="2">Coporate Data</option>
                          </select>
                        </Col>

                        <Network />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.payment_method}
                            name="payment_method"
                            onChange={formOnChange}
                          >
                            <option value="">All Payment Methods</option>
                            <option value="0">ATM</option>
                            <option value="1">Wallet</option>
                            <option value="2">Admin Purchase</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Delivered</option>
                            <option value="1">Paying</option>
                            <option value="2">Charging Merchant</option>
                            <option value="3">Queued</option>
                            <option value="4">Processing</option>
                            <option value="5">Reversed</option>
                            <option value="6">Failed</option>
                            <option value="7">Cancelled</option>
                          </select>
                        </Col>
                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.data_subscriptions_count && (
                          <p>
                            Transactions Count:{" "}
                            {tempData.data_subscriptions_count}
                          </p>
                        )}
                        {tempData.data_subscriptions_amount && (
                          <p>
                            Amount Paid by Customer: ₦
                            {formatCurrency(tempData.data_subscriptions_amount)}
                          </p>
                        )}{" "}
                        {tempData.data_subscriptions_amount && (
                          <p>
                            Commission paid to merchants: ₦
                            {formatCurrency(
                              tempData.data_subscriptions_commission
                            )}
                          </p>
                        )}
                        {tempData.data_subscriptions_amount && (
                          <p>
                            Amount Settled to DataNow: ₦
                            {formatCurrency(
                              tempData.data_subscriptions_amount -
                                tempData.data_subscriptions_commission
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>Cable Subscriptions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "cable_subscriptions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.payment_method}
                            name="payment_method"
                            onChange={formOnChange}
                          >
                            <option value="">All Payment Methods</option>
                            <option value="0">ATM</option>
                            <option value="1">Wallet</option>
                            <option value="2">Admin Purchase</option>
                          </select>
                        </Col>
                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.filterTv}
                            name="filterTv"
                            onChange={formOnChange}
                          >
                            <option value="">All TVs</option>
                            <option value="DSTV">DSTV</option>
                            <option value="GOTV">GOTV</option>
                            <option value="STARTIMES">STARTIMES</option>
                          </select>
                        </Col>
                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Delivered</option>
                            <option value="1">Paying</option>
                            <option value="2">Charging Merchant</option>
                            <option value="3">Queued</option>
                            <option value="4">Processing</option>
                            <option value="5">Reversed</option>
                            <option value="6">Failed</option>
                            <option value="7">Cancelled</option>
                          </select>
                        </Col>
                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.cable_subscriptions_count && (
                          <p>
                            Transactions Count:{" "}
                            {tempData.cable_subscriptions_count}
                          </p>
                        )}
                        {tempData.cable_subscriptions_amount && (
                          <p>
                            Amount Paid by Customer: ₦
                            {formatCurrency(
                              tempData.cable_subscriptions_amount
                            )}
                          </p>
                        )}{" "}
                        {tempData.cable_subscriptions_amount && (
                          <p>
                            Commission paid to merchants: ₦
                            {formatCurrency(
                              tempData.cable_subscriptions_commission
                            )}
                          </p>
                        )}
                        {tempData.cable_subscriptions_amount && (
                          <p>
                            Amount Settled to DataNow: ₦
                            {formatCurrency(
                              tempData.cable_subscriptions_amount -
                                tempData.cable_subscriptions_commission
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>Electric Subscriptions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "electric_subscriptions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.payment_method}
                            name="payment_method"
                            onChange={formOnChange}
                          >
                            <option value="">All Payment Methods</option>
                            <option value="0">ATM</option>
                            <option value="1">Wallet</option>
                            <option value="2">Admin Purchase</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.disco}
                            name="disco"
                            onChange={formOnChange}
                          >
                            <option value="">All Discos</option>
                            <option value="IKEDC">
                              IKEDC - Ikeja Electric
                            </option>
                            <option value="EKEDC">EKEDC - Eko Electric</option>
                            <option value="KEDCO">KEDCO - Kano Electric</option>
                            <option value="PHED">
                              PHED - Port Harcourt Electric
                            </option>
                            <option value="JED">JED - Jos Electric</option>
                            <option value="IBEDC">
                              IBEDC - Ibadan Electric
                            </option>
                            <option value="KAEDCO">
                              KAEDCO - Kaduna Electric
                            </option>
                            <option value="AEDC">AEDC - Abuja Electric</option>
                            <option value="EEDC">EEDC - Enugu Electric</option>
                            <option value="BEDC">BEDC - Benin Electric</option>
                          </select>
                        </Col>
                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Delivered</option>
                            <option value="1">Paying</option>
                            <option value="2">Charging Merchant</option>
                            <option value="3">Queued</option>
                            <option value="4">Processing</option>
                            <option value="5">Reversed</option>
                            <option value="6">Failed</option>
                            <option value="7">Cancelled</option>
                          </select>
                        </Col>
                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.electric_subscriptions_count && (
                          <p>
                            Transactions Count:{" "}
                            {tempData.electric_subscriptions_count}
                          </p>
                        )}
                        {tempData.electric_subscriptions_amount && (
                          <p>
                            Amount Paid by Customer: ₦
                            {formatCurrency(
                              tempData.electric_subscriptions_amount
                            )}
                          </p>
                        )}{" "}
                        {tempData.electric_subscriptions_amount && (
                          <p>
                            Commission paid to merchants: ₦
                            {formatCurrency(
                              tempData.electric_subscriptions_commission
                            )}
                          </p>
                        )}
                        {tempData.electric_subscriptions_amount && (
                          <p>
                            Amount Settled to DataNow: ₦
                            {formatCurrency(
                              tempData.electric_subscriptions_amount -
                                tempData.electric_subscriptions_commission
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>AutoAgent Commissions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) => handleSubmit(e, "auto_funders")}
                    >
                      <Row>
                        <Businesses />

                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.auto_funders_count && (
                          <p>
                            Transactions Count: {tempData.auto_funders_count}
                          </p>
                        )}
                        {tempData.auto_funders_commission && (
                          <p>
                            Total Settled AutoAgent Transactions: ₦
                            {formatCurrency(
                              tempData.auto_funders_count * 5 +
                                tempData.auto_funders_amount * 1 +
                                tempData.auto_funders_commission * 1
                            )}
                          </p>
                        )}
                        {tempData.auto_funders_amount && (
                          <p>
                            Amount settled to Customers: ₦
                            {formatCurrency(tempData.auto_funders_amount)}
                          </p>
                        )}
                        {tempData.auto_funders_commission && (
                          <p>
                            Commission settled to DataNow: ₦
                            {formatCurrency(tempData.auto_funders_commission)}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>Business Transactions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "business_transactions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Successful</option>
                            <option value="1">Pending</option>
                            <option value="2">Insufficient Fund</option>
                            <option value="3">Admin Cancelled</option>
                            <option value="4">Awaiting merchant payment</option>
                            <option value="5">Queued</option>
                            <option value="6">Processing</option>
                            <option value="7">Reversed</option>
                            <option value="8">Failed</option>
                            <option value="9">Cancelled</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transaction_type}
                            name="transaction_type"
                            onChange={formOnChange}
                          >
                            <option value="">All Transaction Types</option>
                            <option value="0">ATM Payment</option>
                            <option value="1">Wallet Payment</option>
                            <option value="2">Admin Payment</option>
                            <option value="3">Merchant Withdrawal</option>
                            <option value="4">Admin Withdrawal</option>
                            <option value="5">Purchase Reversal</option>
                            <option value="6">MarketPlace Purchase</option>
                            <option value="7">Transfer to Customer</option>
                            <option value="8">AutoAgent Payment</option>
                            <option value="9">Commission Payment</option>
                            <option value="10">Data Purchase</option>
                            <option value="11">Airtime Purchase</option>
                            <option value="12">Cable Purchase</option>
                            <option value="13">Electric Purchase</option>
                            <option value="14">Corporte Data Charge</option>
                          </select>
                        </Col>

                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.business_transactions_count && (
                          <p>
                            Number of items Calculated:{" "}
                            {tempData.business_transactions_count}
                          </p>
                        )}
                        {tempData.business_transactions_amount && (
                          <p>
                            Amount Summation: ₦
                            {formatCurrency(
                              tempData.business_transactions_amount
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>Users Transactions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "user_transactions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.status}
                            name="status"
                            onChange={formOnChange}
                          >
                            <option value="">All Statuses</option>
                            <option value="0">Successful</option>
                            <option value="1">Awaiting user payment</option>
                            <option value="2">Awaiting merchant payment</option>
                            <option value="3">Queued</option>
                            <option value="4">Processing</option>
                            <option value="5">Reversed</option>
                            <option value="6">Failed</option>
                            <option value="7">Cancelled</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transaction_type}
                            name="transaction_type"
                            onChange={formOnChange}
                          >
                            <option value="">All Transaction Types</option>
                            <option value="0">ATM Funding</option>
                            <option value="1">Direct bank transfer</option>
                            <option value="2">Purchase Reversal</option>
                            <option value="3">Fund Received from User</option>
                            <option value="4">Admin Withdrawal</option>
                            <option value="5">Data Purchase</option>
                            <option value="6">Airtime Purchase</option>
                            <option value="7">Transfer to User</option>
                            <option value="8">Cable Purchase</option>
                            <option value="9">Electric Purchase</option>
                            <option value="10">Commission Received</option>
                            <option value="11">AutoAgent Payment</option>
                            <option value="12">Transfer from Merchant</option>
                            <option value="13">Referral Bonus Payment</option>
                            <option value="14">Admin transfer</option>
                          </select>
                        </Col>
                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.user_transactions_count && (
                          <p>
                            Number of items Calculated:{" "}
                            {tempData.user_transactions_count}
                          </p>
                        )}
                        {tempData.user_transactions_amount && (
                          <p>
                            Amount Summation: ₦
                            {formatCurrency(tempData.user_transactions_amount)}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow m-1  p-1">
                    <p>
                      <b>Data Wallet Transactions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "data_wallet_transactions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Network />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={
                              formData.data_wallet_transactions_transaction_type
                            }
                            name="data_wallet_transactions_transaction_type"
                            onChange={formOnChange}
                          >
                            <option value="">All Transaction Types</option>
                            <option value="0">Admin Payment</option>
                            <option value="1">Admin Withdrawal</option>
                            <option value="2">
                              Automated Merchant Funding
                            </option>
                            <option value="3">Reversed Subscription</option>
                            <option value="4">Customer Purchase</option>
                            <option value="5">Purchase Reversal</option>
                            <option value="6">Marketplace Purchase</option>
                          </select>
                        </Col>

                        <Col md="5" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="data_wallet_transactions_dateFrom"
                            value={formData.data_wallet_transactions_dateFrom}
                            onChange={formOnChange}
                          />
                        </Col>
                        <label className="mt-2">To</label>
                        <Col md="5" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="data_wallet_transactions_dateTo"
                            value={formData.data_wallet_transactions_dateTo}
                            onChange={formOnChange}
                          />
                        </Col>
                        <div>
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify  text-white " />
                            Submit
                          </button>
                          {tempData.data_wallet_transactions_count && (
                            <p>
                              Number of items Calculated:{" "}
                              {tempData.data_wallet_transactions_count}
                            </p>
                          )}
                          {tempData.data_wallet_transactions_amount && (
                            <p>
                              Amount Summation: ₦
                              {formatCurrency(
                                tempData.data_wallet_transactions_amount
                              )}
                            </p>
                          )}
                        </div>
                      </Row>
                    </AvForm>
                  </Col>
                  {/* ######################################################### */}

                  {/* ######################################################### */}
                  <Col xs="5" className="shadow  m-1  p-1">
                    <p>
                      <b>Marketplace Stock Transactions</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) =>
                        handleSubmit(e, "airtime_stock_transactions")
                      }
                    >
                      <Row>
                        <Businesses />

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={
                              formData.airtime_stock_transactions_transaction_type
                            }
                            name="airtime_stock_transactions_transaction_type"
                            onChange={formOnChange}
                          >
                            <option value="">All Transaction Types</option>
                            <option value="0">Admin Funding</option>
                            <option value="1">Admin Withdrawal</option>
                            <option value="2">Merchant Purchase</option>
                          </select>
                        </Col>

                        <Col md="6" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={
                              formData.airtime_stock_transactions_stock_type
                            }
                            name="airtime_stock_transactions_stock_type"
                            onChange={formOnChange}
                          >
                            <option value="">All Stock Types</option>
                            <option value="0">Data Stock</option>
                            <option value="1">VTU Stock</option>
                          </select>
                        </Col>

                        <Network />

                        <DateTag />
                      </Row>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                        {tempData.airtime_stock_transactions_count && (
                          <p>
                            Number of items Calculated:{" "}
                            {tempData.airtime_stock_transactions_count}
                          </p>
                        )}
                        {tempData.airtime_stock_transactions_amount && (
                          <p>
                            Amount Summation: ₦
                            {formatCurrency(
                              tempData.airtime_stock_transactions_amount
                            )}
                          </p>
                        )}
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
