import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Modal,ModalBody } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
  TempDataContext, FormDataContext,
     BusinessDataContext

} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = () => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);
 
  document.title = "Airtime Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (airtimePlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'id', value: airtimePlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'network', value: airtimePlan.network },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: airtimePlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: airtimePlan.message },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'code', value: airtimePlan.code },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'source', value: airtimePlan.source },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'price', value: airtimePlan.price },});
      tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });   }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("network", String(formData.network));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("message", String(formData.message));
    urlencoded.append("code", String(formData.code));
    urlencoded.append("source", String(formData.source));
    urlencoded.append("price", String(formData.price));
   
 
    var requestOptions = {
      method: formData.id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
       formData.id ?  localStorage.getItem("apiURL") + "airtime_settings/"+
      formData.network  : localStorage.getItem("apiURL") + "airtime_settings"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
     
          formData.id ?    tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "airtimePlans", value:tempData.airtimePlans.map((airtimePlan) => {
              if(data.data.id===airtimePlan.id) return {...airtimePlan, ...data.data}
              return airtimePlan;
             } 
           )}
            })
          : 
          fetchairtimePlans(1);
        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
                formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.airtimePlansLoaded ) {
      fetchairtimePlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'airtimePlansLoaded', value: true },
      });
    }
  }, []);
     
    
  const AirtimePlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.airtimePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.airtimePlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
          
             <td   >{props.airtimePlan.network}</td>           
             <td   >{formatCurrency( props.airtimePlan.price)}</td>           
             <td   >{props.airtimePlan.code}</td>           
             <td   >{props.airtimePlan.source}</td>           
             <td   >{props.airtimePlan.message}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.airtimePlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchairtimePlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving data plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "airtime_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "airtimePlans", value: data.data },
                    });
                    
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
              
                 <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Airtime Plans</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,phone_number:'', password:'',network:'MTN'})
                }}><i className="mdi mdi-plus"></i> Add Airtime Plan</b></p>
                <hr className="border-primary" />
                </div>
                  {tempData.airtimePlans && (
                    <React.Fragment>
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      <th data-priority="1">Server Status</th>
                      <th data-priority="1">Network</th>
                      <th data-priority="1">Price</th>
    <th data-priority="1">Code</th>
                      <th data-priority="1">Source</th>
                      <th data-priority="1">Message</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.airtimePlans.map((airtimePlan) => {
                        return <AirtimePlan key={airtimePlan.id} airtimePlan={airtimePlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.airtimePlans && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
              onClick={() => {  tempDataDispatch({
                type: "SET_FORM_DATA",
                data: { name: "isModalOpen", value:false },
              });}} >
            <span aria-hidden="true">&times;</span>
          </button>
                   <p className="text-uppercase text-left mb-0 " ><b>{formData.plan_id ? 'Edit Airtime Plan': 'Add Airtime Plan'}</b></p>

                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>
                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Network</b>
                </p>
                  <select className="form-control" value={formData.network} name="network" onChange={(e)=>{formOnChange(e);} }>
                  <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>
   
                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Source</b>
                </p>
                  <select className="form-control" value={formData.source} name="source" onChange={(e)=>{formOnChange(e);} }>
                  <option value="DATANOW">DATANOW</option>
                  <option value="GOLAD">GOLAD</option>
                  <option value="SAGECLOUD">SAGECLOUD</option>
                  <option value="VTPASS">VTPASS</option>
                  </select>
  
  
                  <AvField
                  name="price"
                  label="Price (%)"
                  value={formData.price}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Price"
                  type="text"
                 />

                  <AvField
                  name="code"
                  label="Plan Code"
                  value={formData.code}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan code"
                  type="text"
                 />
  
  
                  <AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  

     
                

                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.plan_id ? 'Save Changes': 'Add Airtime Plan'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        