import React, { useContext, useEffect } from "react";
import { Modal } from "reactstrap";
import { TempDataContext } from "../contexts/Contexts";

const ResponseModal = (props) => {
  const { tempData, tempDataDispatch } = useContext(TempDataContext);

  const clearModal = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "responseModal", value: { isOpen: false, text: "" } },
    });

    tempData.clearData &&
      tempDataDispatch({
        type: "CLEAR_FORM_DATA",
      });

    tempData.history && tempData.history.history.push(tempData.history.url);
  };

  return (
    <div>
      {tempData.responseModal && (
        <Modal
          isOpen={tempData.responseModal.isOpen}
          centered={true}
          toggle={() => {
            clearModal();
          }}
        >
          <h1 className="text-center pt-5">
            <i
              className="mdi mdi-check-all  text-success"
              style={{ fontSize: 120 }}
            ></i>
          </h1>
          <p className="text-center text-dark  pb-5 text-wrap word-wrap text-break">
            {tempData.responseModal.text}
          </p>
        </Modal>
      )}
    </div>
  );
};

export default ResponseModal;
