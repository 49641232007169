import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,FormDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);
  
  if(!props.summary) document.title = "DataNow Admin Transfer -DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
 
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions();
  };
  
  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Processing Transaction...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();

    formData.amount && urlencoded.append("amount", String(formData.amount));
    formData.description && urlencoded.append("description", String(formData.description));
    urlencoded.append("operation", String(formData.operation? formData.operation:'ADD' ));
    urlencoded.append("type", String(formData.type? formData.type:'TRANSFER' ));
   
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "datanow_admin_transfer",
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
       
        formDataDispatch({
          type: "CLEAR_FORM_DATA",
      });


        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
    };
    
    
    return (
      <React.Fragment>
      <Container fluid   >
      <Row className="justify-content-center  align-content-center ">
      <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
      <Card className="mini-stat text-dark shadow  ">
      <CardBody className=" ">

      <div className="mb-2">
      <p className="text-uppercase text-left mb-0 "><b>DataNow Admin Transfer</b></p>
        <hr className="border-primary" />
        </div>

      <Row className="pl-2">




{/* ######################################################### */}
<Col md='7' xs="12" className="shadow m-3 p-3">
        {formData.error && <Alert color="danger">{formData.error}</Alert>}

        <AvForm className="mt-2" role="search" onValidSubmit={(e)=>handleSubmit(e)}>
       <Row>
       <Col md="12" xs="12" className="form-group mb-2">

       <p className="  text-left mb-0 mt-2 "
                          >
                          <b>Type</b>
                          </p>
                          <select className="form-control" value={formData.type} name="type" onChange={(e)=>{formOnChange(e);} }>
                          <option value="TRANSFER">From DataNow Wallet</option>
                          <option value="RECONCILIATION">Reconciliation</option>
                          </select>
</Col>

<Col md="12" xs="12" className="form-group mb-2">

<p className="  text-left mb-0 mt-2 "
                   >
                   <b>Operation</b>
                   </p>
                   <select className="form-control" value={formData.operation} name="operation" onChange={(e)=>{formOnChange(e);} }>
                   <option value="ADD">CREDIT</option>
                   <option value="SUB">DEBIT</option>
                   </select>
</Col>
 
 
      
      <Col md="12" xs="12" className="form-group mb-2">
       <AvField
                  name="amount"
                  label="Amount"
                  value={formData.amount}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />  
      </Col>

      <Col md="12" xs="12" className="form-group mb-2">
       <AvField
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter description"
                  type="text"
                 />  
      </Col>
              
       
       
       </Row>
      <div>
       <button  
       className="btn btn-primary"
       type="submit"
        >Execute
       </button>
        </div> 
       </AvForm>
        </Col>

{/* #################################################################### */}

      </Row>
            
        </CardBody>
        </Card>
        </Col>
        </Row>
        </Container>
        
        </React.Fragment>
        );
      };
      
      export default withRouter(Payments);
      