import React, { useContext, useEffect } from "react";
import Layout from "./components/Layout/";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import routes from "./routes";
import "./custom.css";
import "./App.scss";

import {
  BusinessDataContext,
  ModalContext,
  TempDataContext,
} from "./contexts/Contexts";

import logo from "./images/logo-sm.png";

function withLayout(WrappedComponent) {
  return () => {
    return (
      <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    );
  };
}

function App(props) {
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { loaderDispatch } = useContext(ModalContext);
  const { tempDataDispatch } = useContext(TempDataContext);

  /////////////////Development Dat//////////////
  localStorage.setItem("apiURL", "https://api.datanow.ng:2050/api/admin/");
  // localStorage.setItem("apiURL", "http://127.0.0.1:8000/api/admin/");

  const fetchAppData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        if (data.status === "success") {
          loaderDispatch({
            type: "SET_LOADER",
            data: { text: "", isLoading: false },
          });
          businessDataDispatch({
            type: "STORE_APP_DATA",
            businessData: data.data,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    console.log(window.location.pathname);
    if (businessData.staff) {
      loaderDispatch({
        type: "SET_LOADER",
        data: { text: "Loading", isLoading: true },
      });
      fetchAppData();
    }
    var headTitle = document.querySelector("head");
    var setFavicon = document.createElement("link");
    setFavicon.setAttribute("rel", "shortcut icon");
    setFavicon.setAttribute("href", logo);
    headTitle.appendChild(setFavicon);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        businessData.staff ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  return (
    <div>
      <React.Fragment>
        <Router>
          <Switch>
            {routes.map((route, idx) => {
              if (route.withLayout) {
                if (route.ispublic) {
                  return (
                    <Route
                      path={route.path}
                      component={withLayout(route.component)}
                      key={idx}
                    />
                  );
                }
                return (
                  <PrivateRoute
                    path={route.path}
                    component={withLayout(route.component)}
                    key={idx}
                  />
                );
              }
              return (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                />
              );
            })}
          </Switch>
        </Router>
      </React.Fragment>
    </div>
  );
}

export default withRouter(App);
