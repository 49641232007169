import React , {useContext} from "react"
import {  Modal} from 'reactstrap';
 import {ModalContext} from '../contexts/Contexts';
 import loaderImg from '../images/loader.gif'

const Loader = (props) => {
  const { loader } = useContext(ModalContext);
  return (
      <div>
        <Modal  
        isOpen={loader.isLoading}   backdrop="static" keyboard={false} centered={true}>
         <p>
       <img src={loaderImg} width="100" alt="logo" className="img-fluid mx-auto d-block pt-5" />
   </p>
   <p className="text-center pb-5 text-dark" >{loader.text}</p>
            </Modal>
      </div>
    );
  }

  export default Loader 