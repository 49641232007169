import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "App Settings";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (setting) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'id', value: setting.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'setting', value: setting.setting },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'default_value', value: setting.default_value },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'type', value: setting.type },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'visible', value: setting.visible },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("setting", String(formData.setting));
    urlencoded.append("default_value", String(formData.default_value));
    urlencoded.append("type", String(formData.type));
    urlencoded.append("visible", String(formData.visible));
     
    var requestOptions = {
      method: formData.id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
    formData.id ?  localStorage.getItem("apiURL") + "merchant_settings/"+formData.id  : localStorage.getItem("apiURL") + "merchant_settings"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        formData.id ? tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "settings", value:tempData.settings.map((setting) => {
            if(data.data.id===setting.id) return {...setting, ...data.data}
            return setting;
           } 
         )}
          }) : 
          fetchDataPlans(1);

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.settingsLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'settingsLoaded', value: true },
      });
    }
  }, []);
  
   
    
  const Setting = (props) => {
 
    return (
      <React.Fragment >
       <tr>
     
             <td   >{props.setting.setting}</td>
             <td   >{props.setting.default_value}</td>
              <td   >{props.setting.type *1===0?'Dropdown':'Textbox'}</td>
              <td   >{props.setting.visible*1===0?'Visible' : 'Hidden'}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.setting)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving merchant settings...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "merchant_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "settings", value: data.data },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "settingsMaster", value: data.data },
                    });
                  
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Merchant Settings</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,visible:'0', type:'0',default_value:''})
                }}><i className="mdi mdi-plus"></i> Add Merchant Setting</b></p>
                <hr className="border-primary" />
                </div>
              
                   
                  {tempData.settings && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.settings.length} Merchant Settings</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                     
                      <th data-priority="1">Setting</th>
                      <th data-priority="1">Default Value</th>
                       <th data-priority="1">type</th>
                       <th data-priority="1">Visibility to User</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.settings.map((setting) => {
                        return <Setting key={setting.id} setting={setting} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.settings && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>{formData.id ? 'Edit Merchant Setting': 'Add Merchant Setting'}</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

          
                        
<AvField
      label="Setting"
      name="setting"
      value={formData.setting}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Enter Setting"
      type="text"
      errorMessage="Enter valid value"
      required
      />

                             
 <AvField
      label="Default Value"
      name="default_value"
      value={formData.default_value}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Enter Setting"
      type="text"
      errorMessage="Enter valid value"
       
      />

<p className="  text-left mb-0 mt-2 "
                >
                  <b>Type</b>
                </p>
                  <select className="form-control" value={formData.type} name="type" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">Dropdown</option>
                  <option value="1">Textbox</option>
                  </select>
    
    
                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Visibility to User</b>
                </p>
                  <select className="form-control mb-2" value={formData.visible} name="visible" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">Visible</option>
                  <option value="1">Invisible</option>
                  </select>
    
  
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.id ? 'Save Changes': 'Add Merchant Setting'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        