import React, { createContext, useReducer } from 'react';
import { FormDataReducer } from '../reducers/FormDataReducer';

export const FormDataContext = createContext();

const FormDataContextProvider = (props) => {
  const [formData, formDataDispatch] = useReducer(FormDataReducer, {})

  return (
    <FormDataContext.Provider value={{ formData: formData,  formDataDispatch }}>
      {props.children}
    </FormDataContext.Provider>
  );
}
 
export default FormDataContextProvider;