const menuList = [
  {
    url: "/dashboard",
    label: "Dashboard",
    icon: "ti-dashboard  ",
    is_mega: false,
    class_name: "has-submenu ml-1",
  },

  {
    url: "/#",
    label: "Products",
    icon: "ti-signal",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/transactions", label: "Transactions" },
          {
            url: "/data-wallet-subscriptions",
            label: "Data Wallet Transactions",
          },
          { url: "/data-plans", label: "Data Plans" },
          { url: "/data-server", label: "Data Servers" },
          { url: "/business-data-server", label: "Business Data Servers" },
          { url: "/mtn-sme-server", label: "MTN SME Plans" },
          { url: "/airtime-plans", label: "Airtime Plans" },
          { url: "/cable-plans", label: "Cable TV Plans" },
          { url: "/electric-plans", label: "Electric  Plans" },
        ],
      },
    ],
  },

  {
    url: "/#",
    label: "Business",
    icon: "ti-settings",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/businesses", label: "Businesses" },
          { url: "/business-transactions", label: "Business Transactions" },
          { url: "/withdrawals", label: "Withdrawals" },
          { url: "/auto-agent-transactions", label: "Auto Agent Transactions" },
          { url: "/marketplace", label: "Marketplace Stock" },
          {
            url: "/marketplaceTransactions",
            label: "Marketplace Transactions",
          },
          {
            url: "/dataNowWalletTransactions",
            label: "DataNow Wallet Transactions",
          },
          { url: "/settings", label: "General Settings" },
          { url: "/app-settings", label: "App Settings" },
          { url: "/banks", label: "Banks" },
          { url: "/flutterwave-webhook", label: "Flutterwave Webhook" },
          { url: "/vtpass", label: "VTPass Transaction" },
        ],
      },
    ],
  },

  {
    url: "/#",
    label: "Statistics",
    icon: "ti-stats-up",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/statistics", label: "General Statistics" },
          { url: "/server-statistics", label: "Server Statistics" },
          { url: "/accounts", label: "Accounts" },
          { url: "/datanow-admin-transfer", label: "DataNow Admin Transfer" },
          {
            url: "/datanow-data-wallet-transfer",
            label: "DataNow Ntwk Wallet Trf",
          },
        ],
      },
    ],
  },

  {
    url: "/merchants",
    label: "Merchants",
    icon: "ti-id-badge ",
    class_name: "has-submenu",
  },

  {
    url: "/#",
    label: "Customers",
    icon: "ti-face-smile ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/customers", label: "Customers" },
          { url: "/customers-transactions", label: "Customers' Transactions" },
          { url: "/customers-withdrawal", label: "Customer Admin Withdrawal" },
        ],
      },
    ],
  },
  {
    url: "/admins",
    label: "Admins",
    icon: "ti-heart",
    class_name: "has-submenu",
  },

  {
    url: "/#",
    label: "My Account",
    icon: "ti-user ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/profile", label: "My Profile" },
          { url: "/signout", label: "Sign Out" },
        ],
      },
    ],
  },
];

export default menuList;
