const formatCurrency = (currency) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  var currency = formatter.format(currency).substring(3);

  if (currency.startsWith("N") && !currency.startsWith("NaN")) {
    currency = currency.substring(1, currency.length);
    currency = " -" + currency;
  }
  if (currency.includes(".00")) {
    currency = currency.substring(0, currency.length - 3);
  }
  return currency;
};

const formatDate = (date) => {
  // let date = new Date(2019,4,10,15,30,20) //10 May 2019, 3:30:20 PM
  //   let date = new Date(inputDate.replace(/-/g, "/")); //10 May 2019, 3:30:20 PM
  //   let dateStr = date.toLocaleDateString("en-GB", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "2-digit",
  //   }); // 10/05/19
  //   06-02-2023 11:42am

  let dateStr = date.substring(0, 11).replace(" ", "");
  let arr = dateStr.split("-"); // [ '10', '05', '19' ]
  let d = arr[0]; //e.g. 10
  let m = arr[1]; //e.g. 5
  let y = arr[2]; //e.g. 19

  let timeStr = date
    .substring(11)
    .replaceAll(" ", "")
    .replaceAll("am", "")
    .replaceAll("pm", "");
  arr = timeStr.split(":");
  let h = arr[0];
  let i = arr[1];

  if (h !== "12" && date.includes("pm")) h = h * 1 + 12;

  let ymdHms = y + m + d + h + i + "00";
  return ymdHms;
};

export { formatCurrency, formatDate };
