import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = () => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Business-DataNow";

  const templates = ["Smart"];

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const businessSelect = (business) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "business", value: business },
    });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: true },
    });
  };

  const fundWallet = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "business",
        value: { ...tempData.business, fundWallet: true },
      },
    });
  };

  const fundDataWallet = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "business",
        value: { ...tempData.business, fundDataWallet: true },
      },
    });
  };

  const fundAirtimeWallet = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "business",
        value: { ...tempData.business, fundAirtimeWallet: true },
      },
    });
  };

  const navigateToPage = (page) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transactions", value: false },
    });

    fetchTransactions(page);
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  const handleFundSubmit = (e) => {
    e.persist();
    e.preventDefault();
    executeFundWallet();
  };

  useEffect(() => {
    if (!tempData.subscriptionLoaded) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "subscriptionLoaded", value: true },
      });
    }
  }, []);

  const getBusinessName = (businessId) => {
    var businessName = "";
    businessData.businesses.map((business) => {
      if (business.business_id == businessId) businessName = business.name;
    });
    return businessName;
  };

  const executeFundWallet = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Funding wallet...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append(
      "operation",
      String(formData.operation ? formData.operation : "SUB")
    );
    urlencoded.append(
      "type",
      String(formData.type ? formData.type : "RECONCILIATION")
    );
    urlencoded.append("description", String(formData.description));
    urlencoded.append(
      "network",
      String(formData.network ? formData.network : "MTN")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    var endpoint = localStorage.getItem("apiURL");
    if (tempData.business.fundWallet)
      endpoint = endpoint + "fund_business_wallet/";
    if (tempData.business.fundDataWallet)
      endpoint = endpoint + "fund_data_wallet/";
    if (tempData.business.fundAirtimeWallet)
      endpoint = endpoint + "fund_vtu_wallet/";

    fetch(endpoint + tempData.business.business_id, requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formDataDispatch({
            type: "CLEAR_FORM_DATA",
          });

          navigateToPage(tempData.current_page);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "modalOpen", value: true },
        });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  };

  const activateBusinessBank = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Activating business bank...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      localStorage.getItem("apiURL") +
        "activate_business_bank/" +
        tempData.business.business_id,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formDataDispatch({
            type: "CLEAR_FORM_DATA",
          });

          navigateToPage(tempData.current_page);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "modalOpen", value: true },
        });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  };

  const approvePayment = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Activating business...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      localStorage.getItem("apiURL") +
        "activate_business/" +
        tempData.business.business_id,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formDataDispatch({
            type: "CLEAR_FORM_DATA",
          });

          navigateToPage(tempData.current_page);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "modalOpen", value: true },
        });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  };
  const signIntoBusiness = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Signing into business...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      localStorage.getItem("apiURL") +
        "sign_into_business/" +
        tempData.business.business_id,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDataDispatch({
            type: "CLEAR_FORM_DATA",
          });
          window.open("https://datanow.ng?token=" + data.token, "_blank");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "modalOpen", value: true },
        });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  };

  const Business = (props) => {
    return (
      <React.Fragment>
        <tr style={{ cursor: "pointer" }}>
          <td>
            {props.business.business_payment_status * 1 === 0 && (
              <label className="badge   badge-success">Paid</label>
            )}
            {props.business.business_payment_status * 1 === 1 && (
              <label className="badge  badge-danger">Unpaid</label>
            )}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            {getBusinessName(props.business.business_id)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.wallet_balance)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.MTN_data_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            {formatCurrency(props.business.MTN_CORPORATE_data_airtime)}MB
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            {props.business.MTN_data_share}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            {formatCurrency(props.business.MTN_data_balance)}MB
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.GLO_data_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.AIRTEL_data_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.ETISALAT_data_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.MTN_vtu_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.GLO_vtu_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.AIRTEL_vtu_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            ₦{formatCurrency(props.business.ETISALAT_vtu_airtime)}
          </td>
          <td
            onClick={() => {
              businessSelect(props.business);
            }}
          >
            {props.business.business_id}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchTransactions = (page) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving transactions...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.business_payment_status
      ? "&business_payment_status=" + formData.business_payment_status
      : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "business?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          console.log(objectValues);
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "businesses", value: objectValues },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "current_page", value: data.data.current_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "last_page", value: data.data.last_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "per_page", value: data.data.per_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "total", value: data.data.total },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Businesses</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <AvForm
                  className="mt-2"
                  role="search"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <React.Fragment>
                      <Col md="2" xs="6" className="form-group mb-2">
                        <select
                          className="form-control"
                          value={formData.business_payment_status}
                          name="business_payment_status"
                          onChange={formOnChange}
                        >
                          <option value="">All Registered</option>
                          <option value="0">Paid</option>
                          <option value="1">Not Paid</option>
                        </select>
                      </Col>

                      <Col md="2" xs="6" className="form-group mb-2">
                        <select
                          className="form-control"
                          value={formData.pageSize}
                          name="pageSize"
                          onChange={formOnChange}
                        >
                          <option value="">15 per page</option>
                          <option value="30">30 per page</option>
                          <option value="50">50 per page</option>
                          <option value="100">100 per page</option>
                        </select>
                      </Col>
                    </React.Fragment>

                    <div className=" mb-0 ml-3 app-search ">
                      <input
                        type="text"
                        className="form-control bg-light text-dark shadow"
                        placeholder="Search.."
                        name="search"
                        value={formData.search}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                      />
                      <button style={{ marginTop: -12 }} type="submit">
                        <i className="mdi mdi-magnify  mdi-24px text-primary "></i>
                      </button>
                    </div>
                  </Row>
                </AvForm>

                {tempData.businesses && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing (
                      {tempData.per_page * tempData.current_page -
                        tempData.per_page +
                        1}{" "}
                      to{" "}
                      {tempData.current_page * tempData.per_page -
                        tempData.per_page +
                        tempData.businesses.length}{" "}
                      ) of {tempData.total} Businesses
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover tableSmall>
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th data-priority="1">Business</th>
                            <th data-priority="3">Wallet Balance</th>
                            <th data-priority="3">MTN Data Airtime</th>
                            <th data-priority="3">
                              MTN Corporate Data Balance
                            </th>
                            <th data-priority="3">MTN Data Shares</th>
                            <th data-priority="3">MTN Data Balance</th>
                            <th data-priority="1">GLO Data Airtime</th>
                            <th data-priority="1">AIRTEL Data Airtime</th>
                            <th data-priority="1">ETISALAT Data Airtime</th>
                            <th data-priority="3">MTN VTU Airtime</th>
                            <th data-priority="3">GLO VTU Airtime</th>
                            <th data-priority="3">AIRTEL VTU Airtime</th>
                            <th data-priority="3">ETISALAT VTU Airtime</th>
                            <th data-priority="6">ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.businesses.map((business) => {
                            return (
                              <Business key={business.id} business={business} />
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Status</th>
                            <th data-priority="1">Business</th>
                            <th data-priority="3">Wallet Balance</th>
                            <th data-priority="3">MTN Data Airtime</th>
                            <th data-priority="3">
                              MTN Corporate Data Balance
                            </th>
                            <th data-priority="3">MTN Data Shares</th>
                            <th data-priority="3">MTN Data Balance</th>
                            <th data-priority="1">GLO Data Airtime</th>
                            <th data-priority="1">AIRTEL Data Airtime</th>
                            <th data-priority="1">ETISALAT Data Airtime</th>
                            <th data-priority="3">MTN VTU Airtime</th>
                            <th data-priority="3">GLO VTU Airtime</th>
                            <th data-priority="3">AIRTEL VTU Airtime</th>
                            <th data-priority="3">ETISALAT VTU Airtime</th>
                            <th data-priority="6">ID</th>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                  </React.Fragment>
                )}

                {tempData.businesses && (
                  <React.Fragment>
                    <hr className="mt-0" />
                    <p className="text-uppercase text-left mb-0 small">
                      Showing page {tempData.current_page} of{" "}
                      {tempData.last_page}
                    </p>
                    <Row className="ml-1 mt-2 ">
                      <Pagination className="pagination-sm">
                        <PaginationItem
                          disabled={tempData.current_page > 1 ? false : true}
                        >
                          <PaginationLink
                            first
                            onClick={() => {
                              navigateToPage(tempData.current_page - 1);
                            }}
                          />
                        </PaginationItem>
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page - 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page - 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem active>
                          <PaginationLink>
                            {tempData.current_page}
                          </PaginationLink>
                        </PaginationItem>

                        {tempData.last_page >= tempData.current_page + 5 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page + 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page + 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem
                          disabled={
                            tempData.current_page === tempData.last_page
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            onClick={() => {
                              navigateToPage(tempData.current_page + 1);
                            }}
                            last
                          />
                        </PaginationItem>
                      </Pagination>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {tempData.business &&
        !(
          tempData.business.fundWallet ||
          tempData.business.fundDataWallet ||
          tempData.business.fundAirtimeWallet
        ) &&
        tempData.modalOpen && (
          <Modal isOpen={tempData.modalOpen}>
            <ModalBody>
              <div className="mb-2 text-dark">
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "modalOpen", value: false },
                    });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p className="text-uppercase text-left mb-0 ">
                  <b>Edit Transaction Status</b>
                </p>

                <hr className="border-primary" />

                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BUSINESS NAME</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.name}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BUSINESS ID</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.business_id}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>PAYMENT METHOD</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      {tempData.business.business_payment_type * 1 === 0
                        ? "ATM"
                        : "Manual Bank Transfer"}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>AMOUNT PAID</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      ₦
                      {formatCurrency(
                        tempData.business.business_payment_amount
                      )}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BUSINESS WEBSITE</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.website}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BANK STATUS</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      {tempData.business.bank_verified * 1 === 0
                        ? "Verified"
                        : "Not Verified"}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BANK</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      {
                        businessData.banks[
                          businessData.banks.findIndex(
                            (bank) => bank.id * 1 === tempData.business.bank * 1
                          )
                        ].name
                      }
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>ACCOUNT NUMBER</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      {tempData.business.account_number}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>ACCOUNT NAME</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.account_name}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>BVN NUMBER</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.bvn}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>ADDRESS</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.address}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>CAC REG. NO.</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.cac}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>REGISTRATION DATE</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.created_at}</p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>APPLICATION TEMPLATE</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">
                      {templates[tempData.business.template]}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>APPLICATION PRIMARY COLOUR</b>
                  </Col>
                  <Col
                    xs="2"
                    className="ml-3"
                    style={{ backgroundColor: tempData.business.primary_color }}
                  ></Col>
                </Row>

                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>APPLICATION SECONDARY COLOUR</b>
                  </Col>
                  <Col
                    xs="2"
                    className="ml-3"
                    style={{
                      backgroundColor: tempData.business.secondary_color,
                    }}
                  ></Col>
                </Row>
                <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>DESCRIPTION</b>
                  </Col>
                  <Col xs="6">
                    <p className="m-0 p-0">{tempData.business.description}</p>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col sm="6">
                    <div className="text-center bg-primary p-2 m-2 rounded">
                      {" "}
                      <p
                        onClick={() => {
                          signIntoBusiness();
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-uppercase text-light m-0 p-0"
                      >
                        <b>Sign Into Business</b>
                      </p>
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="text-center  bg-warning p-2 m-2 rounded ">
                      {" "}
                      <p
                        onClick={() => {
                          fundWallet();
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-uppercase text-dark  m-0 p-0"
                      >
                        <b>Fund Business Wallet</b>
                      </p>
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="text-center   bg-success p-2 m-2 rounded">
                      {" "}
                      <p
                        onClick={() => {
                          activateBusinessBank();
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-uppercase  text-light  m-0 p-0"
                      >
                        <b>Activate Bank</b>
                      </p>
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="text-center   bg-success p-2 m-2 rounded">
                      {" "}
                      <p
                        onClick={() => {
                          tempData.business.business_payment_status * 1 === 1 &&
                            approvePayment();
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-uppercase  text-light  m-0 p-0"
                      >
                        <b>Approve Payment</b>
                      </p>
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="text-center   bg-danger p-2 m-2 rounded">
                      {" "}
                      <p
                        onClick={() => {
                          fundDataWallet();
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-uppercase  text-light  m-0 p-0"
                      >
                        <b>Fund Data Wallet</b>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>
        )}

      {tempData.business &&
        (tempData.business.fundWallet ||
          tempData.business.fundDataWallet ||
          tempData.business.fundAirtimeWallet) &&
        tempData.modalOpen && (
          <Modal isOpen={tempData.modalOpen}>
            <ModalBody>
              <div className="mb-2 text-dark">
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "modalOpen", value: false },
                    });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p className="text-uppercase text-left mb-0 ">
                  <b>
                    Fund <b className="text-danger">{tempData.business.name}</b>{" "}
                    {tempData.business.fundWallet && "Business"}{" "}
                    {tempData.business.fundDataWallet && "Data"}
                    {tempData.business.fundAirtimeWallet && "Airtime"} Wallet
                  </b>
                </p>

                <hr className="border-primary" />

                <div className="account-card-content">
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}
                  <AvForm
                    className="form-horizontal m-t-30"
                    onValidSubmit={handleFundSubmit}
                  >
                    <p className="  text-left mb-0 mt-2 ">
                      <b>Operation</b>
                    </p>
                    <select
                      className="form-control"
                      value={formData.operation}
                      name="operation"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                    >
                      <option value="SUB">DEBIT</option>
                      <option value="ADD">CREDIT</option>
                    </select>

                    {(tempData.business.fundDataWallet ||
                      tempData.business.fundAirtimeWallet) && (
                      <React.Fragment>
                        <p className="  text-left mb-0 mt-2 ">
                          <b>Network</b>
                        </p>
                        <select
                          className="form-control"
                          value={formData.network}
                          name="network"
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                        >
                          <option value="MTN">MTN</option>
                          <option value="MTN_CORPORATE">
                            MTN CORPORATE DATA
                          </option>
                          <option value="GLO">GLO</option>
                          <option value="AIRTEL">AIRTEL</option>
                          <option value="ETISALAT">ETISALAT</option>
                        </select>
                      </React.Fragment>
                    )}

                    <AvField
                      name="amount"
                      label="Amount"
                      value={formData.amount}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Enter Amount"
                      type="text"
                    />

                    <AvField
                      name="description"
                      label="Description"
                      value={formData.description}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Enter Description"
                      type="text"
                    />

                    <Button
                      className="btn-danger w-md waves-effect waves-light "
                      type="submit"
                    >
                      Execute on {tempData.business.name}
                    </Button>
                  </AvForm>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
