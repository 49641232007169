import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "Data Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (dataPlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'plan_id', value: dataPlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: dataPlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: dataPlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'index', value: dataPlan.index },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'type', value: dataPlan.type },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: dataPlan.message },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'network', value: dataPlan.network },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'source', value: dataPlan.source },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'price', value: dataPlan.price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'airtime_value', value: dataPlan.airtime_value },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'mb_value', value: dataPlan.mb_value },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'code', value: dataPlan.code },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }
   
 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("index", String(formData.index));
    urlencoded.append("message", String(formData.message));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("type", String(formData.type));
    urlencoded.append("source", String(formData.source));
    urlencoded.append("price", String(formData.price));
    urlencoded.append("airtime_value", String(formData.airtime_value));
    urlencoded.append("mb_value", String(formData.mb_value));
    urlencoded.append("code", String(formData.code));
    

    var requestOptions = {
      method: formData.plan_id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.plan_id ?  localStorage.getItem("apiURL") + "data_settings/"+formData.plan_id  : localStorage.getItem("apiURL") + "data_settings"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
   
       formData.plan_id ?  tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "dataPlans", value:tempData.dataPlans.map((dataPlan) => {
            if(data.data.id===dataPlan.id) return {...dataPlan, ...data.data}
            return dataPlan;
           } 
         )}
          }) : 
          fetchDataPlans(1);

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.dataPlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'dataPlansLoaded', value: true },
      });
    }
  }, []);
    
  const filterNetwork =(e)=>{
    var dataPlan=tempData.dataPlansMaster;
   if(e.target.value)
    dataPlan=dataPlan.filter(dataPlan => dataPlan.network=== e.target.value)
    if(tempData.filterStatus)
    dataPlan=dataPlan.filter(dataPlan => dataPlan.status*1 === tempData.filterStatus*1)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataPlans", 
      value: dataPlan
    }});
  }

  
  const filterStatus =(e)=>{
    var dataPlan=tempData.dataPlansMaster;
    if(e.target.value)
    dataPlan=dataPlan.filter(dataPlan => dataPlan.status*1 === e.target.value*1)
    if(tempData.filterNetwork)
    dataPlan=dataPlan.filter(dataPlan => dataPlan.network=== tempData.filterNetwork)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataPlans", 
      value: dataPlan
    }});
  }

    
  const DataPlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.dataPlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.dataPlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
        <td   > 
      {businessData.admin_settings[props.dataPlan.network+"_data_master"]*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {businessData.admin_settings[props.dataPlan.network+"_data_master"]*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   >{props.dataPlan.network}</td>
             <td   >{props.dataPlan.source}</td>
             <td   >{props.dataPlan.name}</td>
             <td  >{formatCurrency(props.dataPlan.price)}</td>
             <td  >{formatCurrency(props.dataPlan.airtime_value)}</td>
             <td  >{formatCurrency(props.dataPlan.mb_value)}</td>
             <td   >{props.dataPlan.type*1===0 ? 'SME Data' : (props.dataPlan.type*1===1 ? 'Normal Data':'Coporate Data')}</td>
             <td   >{props.dataPlan.code}</td>
             <td   >{props.dataPlan.index}</td>
             <td   >{props.dataPlan.message}</td>
             <td   >{props.dataPlan.id}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.dataPlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        
            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving data plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "data_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "dataPlans", value: data.data },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "dataPlansMaster", value: data.data },
                    });
                  
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
          
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Data Plans</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,phone_number:'', password:'',network:'MTN',type:0})
                }}><i className="mdi mdi-plus"></i> Add Data Plan</b></p>
                <hr className="border-primary" />
                </div>
                 <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={tempData.filterNetwork} name="filterNetwork" onChange={(e)=>{formOnChange(e); filterNetwork(e)} }>
                  <option value="">All Networks</option>
                  <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>


              

                  </Col>

                      <Col md="2" xs="6" className="form-group mb-2">
                          <select className="form-control" value={tempData.filterStatus} name="filterStatus"  onChange={(e)=>{formOnChange(e); filterStatus(e)} }>
                          <option value="">All Statuses</option>
                          <option value={0}>Active</option>
                          <option value={1}>Disabled</option>
                         </select>
                        </Col>
                  </Row>
                   
                  {tempData.dataPlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.dataPlans.length} Data Plans</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      
                      <th data-priority="1">Server Plan Status</th>
                      <th data-priority="1">Server Network Status</th>
                      <th data-priority="1">Network</th>
                      <th data-priority="1">Source</th>
                      <th data-priority="1">Name</th>
                      <th data-priority="1">Price</th>
                      <th data-priority="1">Airtime Value</th>
                      <th data-priority="1">MB Value</th>
                      <th data-priority="1">Plan Type</th>
                      <th data-priority="1">Code</th>
                       <th data-priority="1">Index</th>
                       <th data-priority="1">Message</th>
                       <th data-priority="1">ID</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.dataPlans.map((dataPlan) => {
                        return <DataPlan key={dataPlan.id} dataPlan={dataPlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.dataPlans && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="text-uppercase text-left mb-0 " ><b>{formData.plan_id ? 'Edit Data Plan': 'Add Data Plan'}</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

          <p className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Network</b>
                </p>
                  <select className="form-control" value={formData.network} name="network" onChange={(e)=>{formOnChange(e);} }>
                  <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>

                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Type</b>
                </p>
                <select required className="form-control mb-2" value={formData.type} name="type" onChange={formOnChange}>
                <option value="0">SME Data</option>
                <option value="1">Normal Data</option>
                <option value="2">Coporate Data</option>
                  </select>

                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Source</b>
                </p>
                  <select className="form-control" value={formData.source} name="source" onChange={(e)=>{formOnChange(e);} }>
                  <option value="DATANOW">DATANOW</option>
                  <option value="GOLAD">GOLAD</option>
                  <option value="SAGECLOUD">SAGECLOUD</option>
                  <option value="VTPASS">VTPASS</option>
                  </select>

                 <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter plan name"
                  type="text"
                 />
                 
               


<AvField
                  name="price"
                  label="Price"
                  value={formData.price}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Price"
                  type="text"
                 />

<AvField
      label="Airtime Value (in Airtime)"
      name="airtime_value"
      value={formData.airtime_value}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Transaction Charge"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

<AvField
      label="MB Value (in MB)"
      name="mb_value"
      value={formData.mb_value}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Transaction Charge"
      type="text"
      errorMessage="Enter valid amount"
      required
      />
                 
                  <AvField
                  name="code"
                  label="Plan Code"
                  value={formData.code}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan code"
                  type="text"
                 />
                  
<AvField
      label="Plan Index"
      name="index"
      value={formData.index}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Plan index"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

<AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.plan_id ? 'Save Changes': 'Add Data Plan'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        