import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Admins-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const planSelect = (admin) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "id", value: admin.id },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "phone_number", value: admin.phone_number },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "name", value: admin.name },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "email", value: admin.email },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "clearance_level", value: admin.clearance_level },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "staff_id", value: admin.staff_id },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: true },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: false },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", String(formData.email));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("name", String(formData.name));
    urlencoded.append("clearance_level", String(formData.clearance_level));
    urlencoded.append("password", String(formData.password));

    var requestOptions = {
      method: formData.id ? "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.id
        ? localStorage.getItem("apiURL") + "staff/" + formData.staff_id
        : localStorage.getItem("apiURL") + "staff",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formData.id
            ? tempDataDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "admins",
                  value: tempData.admins.map((admin) => {
                    if (data.data.id === admin.id)
                      return { ...admin, ...data.data };
                    return admin;
                  }),
                },
              })
            : fetchDataPlans(1);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "isModalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "isModalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  useEffect(() => {
    if (!tempData.adminsLoaded) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "adminsLoaded", value: true },
      });
    }
  }, []);

  const clearanceLevels = ["Admin", "Supervisor", "Customer Service"];

  const Admin = (props) => {
    return (
      <React.Fragment>
        <tr>
          <td>{props.admin.email}</td>
          <td>{props.admin.phone_number}</td>
          <td>{props.admin.name}</td>
          <td>{clearanceLevels[props.admin.clearance_level]}</td>
          <td>{props.admin.created_at}</td>
          <td>{props.admin.id}</td>
          <td
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              planSelect(props.admin);
            }}
          >
            Edit
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchDataPlans = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving data servers...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "staff", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "admins", value: data.data },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "adminsMaster", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>DataNow Admins</b>
                    <b
                      className="text-primary float-right"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        planSelect({
                          id: false,
                          phone_number: "",
                          password: "",
                          network: "MTN",
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"></i> Add Admin
                    </b>
                  </p>
                  <hr className="border-primary" />
                </div>

                {tempData.admins && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing {tempData.admins.length} Admins
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th data-priority="1">Phone No.</th>
                            <th data-priority="3">Name</th>
                            <th data-priority="3">Clearance Level</th>
                            <th data-priority="6">Reg. Date</th>
                            <th data-priority="6">Staff ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.admins.map((admin) => {
                            return <Admin key={admin.id} admin={admin} />;
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {tempData.admins && (
        <Modal isOpen={tempData.isModalOpen}>
          <ModalBody>
            <div className="mb-2 text-dark">
              <button
                type="button"
                className="close"
                onClick={() => {
                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "isModalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="text-uppercase text-left mb-0 ">
                <b>{formData.id ? "Edit Admin" : "Add Admin"}</b>
              </p>

              <hr className="border-primary" />

              <div className="account-card-content">
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}
                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <p className="  text-left mb-0 mt-2 ">
                    <b>Clearance Level</b>
                  </p>
                  <select
                    className="form-control"
                    value={formData.clearance_level}
                    name="clearance_level"
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                  >
                    {clearanceLevels.map((clearance, index) => {
                      return (
                        <option key={index} value={index}>
                          {clearance}
                        </option>
                      );
                    })}
                  </select>
                  <AvField
                    name="name"
                    label="name"
                    value={formData.name}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter staff name"
                    type="text"
                    required
                  />

                  <AvField
                    name="email"
                    label="Email"
                    value={formData.email}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter Email"
                    type="email"
                    required
                  />

                  <AvField
                    name="phone_number"
                    label="Phone Number"
                    value={formData.phone_number}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter staff phone number"
                    type="text"
                    required
                    validate={{
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Enter valid phone number",
                      },
                    }}
                  />

                  {!formData.id && (
                    <AvField
                      name="password"
                      label="Password"
                      value={formData.password}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Enter a password for Staff (Min 6 characters)"
                      type="text"
                      required
                    />
                  )}

                  <Button
                    className="btn-primary w-md waves-effect waves-light "
                    type="submit"
                  >
                    {formData.id ? "Save Changes" : "Add Admin"}
                  </Button>
                </AvForm>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
