import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = () => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = 'General Settings-DataNow';
  

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const editBusiness = (business ) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'MTN_data_master', value: tempData.settings.MTN_data_master },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'GLO_data_master', value: tempData.settings.GLO_data_master },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'AIRTEL_data_master', value: tempData.settings.AIRTEL_data_master },
    });

    
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'ETISALAT_data_master', value: tempData.settings.ETISALAT_data_master },
    });


    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'fund_status_atm', value: tempData.settings.fund_status_atm },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'fund_status_auto', value: tempData.settings.fund_status_auto },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'fund_status_bank', value: tempData.settings.fund_status_bank },
    });



    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'data_subscription_charge', value: tempData.settings.data_subscription_charge },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'airtime_subscription_charge', value: tempData.settings.airtime_subscription_charge },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'minimum_airtime_purchase', value: tempData.settings.minimum_airtime_purchase },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'maximum_airtime_purchase', value: tempData.settings.maximum_airtime_purchase },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'merchant_withdrawal_charge', value: tempData.settings.merchant_withdrawal_charge },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'minimum_merchant_withdrawal', value: tempData.settings.minimum_merchant_withdrawal },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'maximum_merchant_withdrawal_charge', value: tempData.settings.maximum_merchant_withdrawal_charge },
    });
 
  

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'business_registration_payment', value: tempData.settings.business_registration_payment },
    });
 
     
    tempDataDispatch({type: "SET_FORM_DATA",data: { name: 'modalOpen', value: true}});
   }
 
  
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    saveBusinessSettings();
  };

   
  useEffect(() => {
    if(!tempData.subscriptionLoaded ) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'subscriptionLoaded', value: true },
      });
    }
  }, []);
  
                     
  
const saveBusinessSettings =()=> {
 
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value:false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("MTN_data_master", String(formData.MTN_data_master));
    urlencoded.append("GLO_data_master", String(formData.GLO_data_master));
    urlencoded.append("AIRTEL_data_master", String(formData.AIRTEL_data_master));
    urlencoded.append("ETISALAT_data_master", String(formData.ETISALAT_data_master));
    urlencoded.append("fund_status_atm", String(formData.fund_status_atm));
    urlencoded.append("fund_status_auto", String(formData.fund_status_auto));
    urlencoded.append("fund_status_bank", String(formData.fund_status_bank));
    urlencoded.append("data_subscription_charge", String(formData.data_subscription_charge));
    urlencoded.append("airtime_subscription_charge", String(formData.airtime_subscription_charge));
    urlencoded.append("minimum_airtime_purchase", String(formData.minimum_airtime_purchase));
    urlencoded.append("maximum_airtime_purchase", String(formData.maximum_airtime_purchase));
    urlencoded.append("merchant_withdrawal_charge", String(formData.merchant_withdrawal_charge));
    urlencoded.append("minimum_merchant_withdrawal", String(formData.minimum_merchant_withdrawal));
    urlencoded.append("maximum_merchant_withdrawal_charge", String(formData.maximum_merchant_withdrawal_charge));
    urlencoded.append("business_registration_payment", String(formData.business_registration_payment));
     

     var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
    };
     
    fetch(
      localStorage.getItem("apiURL")+'admin_settings',
            requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: 'settings', value:data.data },
      });
        formDataDispatch({
          type: "CLEAR_FORM_DATA",
        });
        
         } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "modalOpen", value:true },
      });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "modalOpen", value:true },
      });
        }
      })
      .catch((error) => {
        
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "modalOpen", value:true },
      });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  
  }
 
 
            const fetchTransactions = (page) => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving transactions...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
               var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "admin_settings?" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "settings", value: data.data },
                    });
                    
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                 <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>General Settings</b></p>
                <hr className="border-primary" />
                </div>
                
              {tempData.settings&& (
                <React.Fragment>
          <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MTN DATA MASTER</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.MTN_data_master*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.MTN_data_master*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>

         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>GLO DATA MASTER</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.GLO_data_master*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.GLO_data_master*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>


         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>AIRTEL DATA MASTER</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.AIRTEL_data_master*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.AIRTEL_data_master*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>


         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>ETISALAT DATA MASTER</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.ETISALAT_data_master*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.ETISALAT_data_master*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>


         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>ATM FUND STATUS</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.fund_status_atm*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.fund_status_atm*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>


         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>AUTO AGENT FUND STATUS</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.fund_status_auto*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.fund_status_auto*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>



         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>BANK TRANSFER FUND STATUS</b></Col><Col xs="6"  ><p className="m-0 p-0">
           {tempData.settings.fund_status_bank*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {tempData.settings.fund_status_bank*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
         </p></Col></Row>



         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>DATA SUBSCRIPTION CHARGE</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.data_subscription_charge)}</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>AIRTIME SUBSCRIPTION CHARGE</b></Col><Col xs="6"  ><p className="m-0 p-0">{formatCurrency(tempData.settings.airtime_subscription_charge)}%</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MINIMUM AIRTIME PURCHASE</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.minimum_airtime_purchase)}</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MAXIMUM AIRTIME PURCHASE</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.maximum_airtime_purchase)}</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MERCAHNT WITHDRAWAL CHARGE</b></Col><Col xs="6"  ><p className="m-0 p-0">{formatCurrency(tempData.settings.merchant_withdrawal_charge)}%</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MINIMUM MERCHANT WITHDRAWAL</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.minimum_merchant_withdrawal)}</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>MAXIMUM MERCHANT WITHDRAWAL CHARGE</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.maximum_merchant_withdrawal_charge)}</p></Col></Row>
         <Row  className="mt-2 mb-3" style={{fontSize:12}}><Col xs="6"><b>BUSINESS REGISTRATION FEE</b></Col><Col xs="6"  ><p className="m-0 p-0">₦{formatCurrency(tempData.settings.business_registration_payment)}</p></Col></Row>
                   <hr />
        
                <div className="text-center"> <p
                  onClick={() => {
                    editBusiness()  
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-uppercase   mb-2 text-danger "
                >
                  <b>Edit Settings</b>
                </p>
                </div>  
                </React.Fragment>
 )}
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
  

{tempData.modalOpen && (<Modal isOpen={tempData.modalOpen}  >
 
 <ModalBody >
<div className="mb-2 text-dark">
<button type="button" className="close"
    onClick={() => {    tempDataDispatch({type: "SET_FORM_DATA",data: { name: 'modalOpen', value: false}}); }} >
    <span aria-hidden="true">&times;</span>
  </button>
          <p className="text-uppercase text-left mb-0 " ><b>EDIT GENERAL SETTINGS</b></p>
        
        
          <hr className="border-primary" />
        
          <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >
 
 <p className="  text-left mb-0 mt-2 "
                >
                  <b>MTN Data Master</b>
                </p>
                  <select className="form-control" value={formData.MTN_data_master} name="MTN_data_master" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>



                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Glo Data Master</b>
                </p>
                  <select className="form-control" value={formData.GLO_data_master} name="GLO_data_master" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>



                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Airtel Data Master</b>
                </p>
                  <select className="form-control" value={formData.AIRTEL_data_master} name="AIRTEL_data_master" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>



                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Etisalat Data Master</b>
                </p>
                  <select className="form-control" value={formData.ETISALAT_data_master} name="ETISALAT_data_master" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>


                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>ATM Fund Status</b>
                </p>
                  <select className="form-control" value={formData.fund_status_atm} name="fund_status_atm" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>


                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Auto Agent Fund Status</b>
                </p>
                  <select className="form-control" value={formData.fund_status_auto} name="fund_status_auto" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>


                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Bank Transfer Fund Status</b>
                </p>
                  <select className="form-control" value={formData.fund_status_bank} name="fund_status_bank" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">ENABLED</option>
                  <option value="1">DISABLED</option>
                  </select>


                  <AvField
                  name="data_subscription_charge"
                  label="Data Subscription Charge (₦)"
                  value={formData.data_subscription_charge}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />


              <AvField
                  name="airtime_subscription_charge"
                  label="Airtime Subscription Charge (%)"
                  value={formData.airtime_subscription_charge}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />

                
              <AvField
                  name="minimum_airtime_purchase"
                  label="Minimum Airtime Purchase"
                  value={formData.minimum_airtime_purchase}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />


<AvField
                  name="maximum_airtime_purchase"
                  label="Maximum Airtime Purchase"
                  value={formData.maximum_airtime_purchase}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />


                 

<AvField
                  name="merchant_withdrawal_charge"
                  label="Merchant withdrawal Charge (%)"
                  value={formData.merchant_withdrawal_charge}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />

<AvField
                  name="minimum_merchant_withdrawal"
                  label="Minimum Merchant withdrawal (₦)"
                  value={formData.minimum_merchant_withdrawal}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />


                 
<AvField
                  name="maximum_merchant_withdrawal_charge"
                  label="Maximum Merchant withdrawal Charge (₦)"
                  value={formData.maximum_merchant_withdrawal_charge}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />


                  <AvField
                  name="business_registration_payment"
                  label="Business Registration Fee"
                  value={formData.business_registration_payment}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />
                   
                
                <Button
                  className="btn-danger w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>   

       </div>
</ModalBody>
</Modal>)
}
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        