import React, { useContext, useEffect, useRef } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import { useReactToPrint } from "react-to-print";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Data Plans-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const planSelect = (dataServer) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "id", value: dataServer.id },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "phone_number", value: dataServer.phone_number },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "password", value: dataServer.password },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "network", value: dataServer.network },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "name", value: dataServer.name },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: true },
    });
  };

  const MyMTNAppOTPLogin = (e, dataServer) => {
    e.persist();
    e.preventDefault();
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "dataServers",
        value: tempData.dataServers.map((existingdataServer) => {
          if (dataServer.id === existingdataServer.id)
            existingdataServer.gettingOTP = true;
          return existingdataServer;
        }),
      },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(dataServer.id));
    urlencoded.append("otp", String(formData.otp));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "mymtnappotplogin", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "dataServers",
              value: tempData.dataServers.map((existingdataServer) => {
                if (data.data.id === existingdataServer.id)
                  return { ...data.data };
                return existingdataServer;
              }),
            },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const MyMTNAppLogin = (dataServer) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "dataServers",
        value: tempData.dataServers.map((existingdataServer) => {
          if (dataServer.id === existingdataServer.id)
            existingdataServer.loggingin = true;
          return existingdataServer;
        }),
      },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(dataServer.id));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "mymtnapplogin", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "dataServers",
              value: tempData.dataServers.map((newDataServer) => {
                if (newDataServer.id === dataServer.id) {
                  newDataServer.loggingin = false;
                  newDataServer.otpRequested = true;
                }
                return newDataServer;
              }),
            },
          });
        } else if (data.errors) {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "dataServers",
              value: tempData.dataServers.map((newDataServer) => {
                if (newDataServer.id === dataServer.id) {
                  newDataServer.loggingin = false;
                  newDataServer.otpRequested = false;
                }
                return newDataServer;
              }),
            },
          });
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: false },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("password", String(formData.password));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("name", String(formData.name));

    var requestOptions = {
      method: formData.id ? "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.id
        ? localStorage.getItem("apiURL") + "data_server/" + formData.id
        : localStorage.getItem("apiURL") + "data_server",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formData.id
            ? tempDataDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "dataServers",
                  value: tempData.dataServers.map((dataServer) => {
                    if (data.data.id === dataServer.id)
                      return { ...dataServer, ...data.data };
                    return dataServer;
                  }),
                },
              })
            : fetchDataPlans(1);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  useEffect(() => {
    if (!tempData.dataServersLoaded) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataServersLoaded", value: true },
      });
    }
  }, []);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle:
      "Data Server Stat-" +
      new Date()
        .toLocaleString()
        .slice(0, 17)
        .replaceAll("/", "-")
        .replaceAll(",", "")
        .replaceAll(":", "-"),
  });

  const filterNetwork = (e) => {
    var dataServer = tempData.dataServersMaster;
    if (e.target.value)
      dataServer = dataServer.filter(
        (dataServer) => dataServer.network === e.target.value
      );

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataServers", value: dataServer },
    });
  };

  const DataServer = (dataServer) => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {dataServer.gettingOTP ? (
              <label className="text-primary text-center">
                Obtaining Token...
              </label>
            ) : dataServer.otpRequested ? (
              <AvForm onValidSubmit={(e) => MyMTNAppOTPLogin(e, dataServer)}>
                <input
                  className="form-input"
                  value={formData.otp}
                  name="otp"
                  onChange={formOnChange}
                  placeholder="OTP"
                  key="input"
                />
                <Button
                  className="btn-primary waves-effect waves-light  ml-2"
                  type="submit"
                >
                  Login
                </Button>
              </AvForm>
            ) : dataServer.loggingin ? (
              <label className="text-primary text-center">
                Requesting for OTP...
              </label>
            ) : (
              (new Date() - new Date(dataServer.updated_at)) / 60000 > 30 &&
              dataServer.network == "MTN" && (
                <button
                  className="btn btn-primary small"
                  onClick={() => {
                    MyMTNAppLogin(dataServer);
                  }}
                >
                  MyMTNApp Login
                </button>
              )
            )}
          </td>
          <td>
            {(new Date() - new Date(dataServer.updated_at)) / 60000 < 30 ? (
              <label className="badge   badge-success  "> Logged In</label>
            ) : (new Date() - new Date(dataServer.updated_at)) / 60000 < 60 ? (
              <label className="badge   badge-warning  "> Timed Out</label>
            ) : (
              <label className="badge   badge-danger  ">Logged Out</label>
            )}
          </td>

          <td>
            {new Date().getDate() ===
            new Date(dataServer.last_sent_date).getDate() ? (
              <label className="badge   badge-success small">Sent</label>
            ) : (
              <label className="badge   badge-danger small">Not Sent</label>
            )}
          </td>

          <td>{dataServer.network}</td>
          <td>{dataServer.name}</td>
          <td>{dataServer.phone_number}</td>
          <td>₦{formatCurrency(dataServer.airtime_balance)}</td>
          <td>{formatCurrency(dataServer.data_balance)}MB</td>
          <td>{dataServer.password}</td>
          <td>{dataServer.id}</td>
          <td
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              planSelect(dataServer);
            }}
          >
            Edit
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchDataPlans = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving data servers...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "data_server", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "dataServers", value: data.data },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "dataServersMaster", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Data Servers </b>
                    <b
                      className="text-primary float-right"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        planSelect({
                          id: false,
                          phone_number: "",
                          password: "1111",
                          network: "MTN",
                          name:
                            "S" +
                            (tempData.dataServers[0].name.replace(/^\D+/g, "") *
                              1 +
                              1),
                        });
                        // planSelect({
                        //   id: false,
                        //   phone_number: "",
                        //   password: "",
                        //   network: "MTN",
                        // });
                      }}
                    >
                      <i className="mdi mdi-plus"></i> Add Data Server
                    </b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                    <select
                      className="form-control"
                      value={tempData.filterNetwork}
                      name="filterNetwork"
                      onChange={(e) => {
                        formOnChange(e);
                        filterNetwork(e);
                      }}
                    >
                      <option value="">All Networks</option>
                      <option value="MTN">MTN</option>
                      <option value="GLO">GLO</option>
                      <option value="AIRTEL">AIRTEL</option>
                      <option value="ETISALAT">ETISALAT</option>
                    </select>
                  </Col>
                  <button className="btn btn-primary m-2" onClick={handlePrint}>
                    Download
                  </button>
                </Row>

                {tempData.dataServers && (
                  <React.Fragment>
                    <div className="table-rep-plugin m-2" ref={printRef}>
                      <p className="text-uppercase text-left mb-2 mt-3  ">
                        Showing {tempData.dataServers.length} Data Servers{" "}
                        <b>
                          {new Date()
                            .toLocaleString()
                            .slice(0, 17)
                            .replaceAll("/", "-")
                            .replaceAll(",", "")
                            .replaceAll(":", "-")}
                        </b>
                      </p>
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th data-priority="2"></th>
                            <th data-priority="1">Login Status</th>
                            <th data-priority="1">Data Sent Today</th>
                            <th data-priority="1">Network</th>
                            <th data-priority="1">Name</th>
                            <th data-priority="1">Phone Number</th>
                            <th data-priority="1">Airtime Balance</th>
                            <th data-priority="1">Data Balance</th>
                            <th data-priority="1">Password</th>
                            <th data-priority="1">ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.dataServers.map((dataServer) => {
                            return DataServer(dataServer);
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {tempData.dataServers && (
        <Modal isOpen={tempData.isModalOpen}>
          <ModalBody>
            <div className="mb-2 text-dark">
              <button
                type="button"
                className="close"
                onClick={() => {
                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "isModalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="text-uppercase text-left mb-0 ">
                <b>{formData.id ? "Edit Data Server" : "Add Data Server"}</b>
              </p>

              <hr className="border-primary" />

              <div className="account-card-content">
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}
                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <p className="  text-left mb-0 mt-2 ">
                    <b>Network</b>
                  </p>
                  <select
                    className="form-control"
                    value={formData.network}
                    name="network"
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                  >
                    <option value="MTN">MTN</option>
                    <option value="GLO">GLO</option>
                    <option value="AIRTEL">AIRTEL</option>
                    <option value="ETISALAT">ETISALAT</option>
                  </select>
                  <AvField
                    label="Sever Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Server Name"
                    type="text"
                    errorMessage="Enter valid number"
                    required
                  />
                  <AvField
                    label="Phone Number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Phone Number"
                    type="text"
                    errorMessage="Enter valid number"
                    required
                  />

                  <AvField
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Server Password"
                    type="text"
                    errorMessage="Enter valid password"
                    required
                  />

                  <Button
                    className="btn-primary w-md waves-effect waves-light "
                    type="submit"
                  >
                    {formData.id ? "Save Changes" : "Add Data Server"}
                  </Button>
                </AvForm>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
