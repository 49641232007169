import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Banks-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const planSelect = (bank) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "id", value: bank.id },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "name", value: bank.name },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "monnify_code", value: bank.monnify_code },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "kuda_code", value: bank.kuda_code },
    });
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "status", value: bank.status },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: true },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: false },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(formData.id));
    urlencoded.append("name", String(formData.name));
    urlencoded.append("monnify_code", String(formData.monnify_code));
    urlencoded.append("kuda_code", String(formData.kuda_code));
    urlencoded.append("status", String(formData.status));

    var requestOptions = {
      method: formData.id ? "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.id
        ? localStorage.getItem("apiURL") + "bank/" + formData.id
        : localStorage.getItem("apiURL") + "bank",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formData.id
            ? tempDataDispatch({
                type: "SET_FORM_DATA",
                data: {
                  name: "banks",
                  value: tempData.banks.map((bank) => {
                    if (data.data.id === bank.id)
                      return { ...bank, ...data.data };
                    return bank;
                  }),
                },
              })
            : fetchDataPlans(1);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "isModalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "isModalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  useEffect(() => {
    if (!tempData.banksLoaded) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "banksLoaded", value: true },
      });
    }
  }, []);

  const clearanceLevels = ["Bank", "Supervisor", "Customer Service"];

  const Bank = (props) => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {props.bank.status * 1 === 0 && (
              <label className="badge   badge-success">Active</label>
            )}
            {props.bank.status * 1 === 1 && (
              <label className="badge  badge-danger">Disabled</label>
            )}
          </td>
          <td>{props.bank.id}</td>
          <td>{props.bank.name}</td>
          <td>{props.bank.monnify_code}</td>
          <td>{props.bank.kuda_code}</td>
          <td
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              planSelect(props.bank);
            }}
          >
            Edit
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchDataPlans = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving data servers...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "bank", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "banks", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>DataNow Banks</b>
                    <b
                      className="text-primary float-right"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        planSelect({
                          id: false,
                          phone_number: "",
                          password: "",
                          network: "MTN",
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"></i> Add Bank
                    </b>
                  </p>
                  <hr className="border-primary" />
                </div>

                {tempData.banks && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing {tempData.banks.length} Banks
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th data-priority="3">ID</th>
                            <th data-priority="3">Name</th>
                            <th data-priority="3">Monnify Code</th>
                            <th data-priority="3">Kuda Code</th>
                            <th data-priority="3"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.banks.map((bank) => {
                            return <Bank key={bank.id} bank={bank} />;
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {tempData.banks && (
        <Modal isOpen={tempData.isModalOpen}>
          <ModalBody>
            <div className="mb-2 text-dark">
              <button
                type="button"
                className="close"
                onClick={() => {
                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "isModalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="text-uppercase text-left mb-0 ">
                <b>{formData.id ? "Edit Bank" : "Add Bank"}</b>
              </p>

              <hr className="border-primary" />

              <div className="account-card-content">
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}
                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <p className="  text-left mb-0 mt-2 ">
                    <b>Status</b>
                  </p>
                  <select
                    required
                    className="form-control mb-2"
                    value={formData.status}
                    name="status"
                    onChange={formOnChange}
                  >
                    <option value="0">Active</option>
                    <option value="1">Disabled</option>
                  </select>

                  <AvField
                    name="name"
                    label="name"
                    value={formData.name}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter staff name"
                    type="text"
                    required
                  />
                  <AvField
                    name="monnify_code"
                    label="Monnify Code"
                    value={formData.monnify_code}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter Monnify Code"
                    type="text"
                  />
                  <AvField
                    name="kuda_code"
                    label="Kuda Code"
                    value={formData.kuda_code}
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    placeholder="Enter Kuda Code"
                    type="text"
                  />

                  <Button
                    className="btn-primary w-md waves-effect waves-light "
                    type="submit"
                  >
                    {formData.id ? "Save Changes" : "Add Bank"}
                  </Button>
                </AvForm>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
