import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Statistics-DataNow";
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  useEffect(() => {
    if (!tempData.fetchWallets) {
      fetchWallets();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "fetchWallets", value: true },
      });
    }
  }, []);

  const fetchWallets = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving Wallets...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "wallets", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "wallets", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  function ReconcileStats() {
    const calculatedValue =
      tempData.wallets.KUDA * 1 +
      tempData.wallets.ACCESS * 1 +
      tempData.wallets.FLUTTERWAVE * 1 +
      tempData.wallets.MONNIFY * 1 +
      formData.quickbook_value * 1;

    const difference = calculatedValue - tempData.wallets.total_value;
    var status = "";
    if (calculatedValue > tempData.wallets.total_value)
      status = "Surplus of ₦" + formatCurrency(Math.abs(difference));
    else status = "Deficit of ₦" + formatCurrency(Math.abs(difference));

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "status", value: status },
    });
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow pb-4">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Wallets</b>
                    <AvForm onSubmit={() => ReconcileStats()}>
                      <AvField
                        name="quickbook_value"
                        value={formData.quickbook_value}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Quickbooks Datanow Balance"
                        type="text"
                        errorMessage="Enter valid price"
                        required
                        className="mt-2 mb-0 wrap span "
                      />
                      <Button
                        className="btn-primary w-md waves-effect waves-light mt-1"
                        type="submit"
                      >
                        Reconcile
                      </Button>{" "}
                      {formData.status && formData.status}
                    </AvForm>
                  </p>
                  <hr className="border-primary" />
                </div>
                {tempData.wallets && (
                  <React.Fragment>
                    <Row>
                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody className="bg-danger text-white">
                            <p>Total System Value</p>
                            <h5>
                              {" "}
                              ₦{formatCurrency(tempData.wallets.total_value)}
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody>
                            <Row>
                              <Col lg="3" xs="3">
                                <h1>
                                  <i className="fas fa-shopping-cart text-dark"></i>
                                </h1>
                              </Col>
                              <Col xs="9">
                                <h6 className=" text-uppercase mt-2 small">
                                  Marketplace Value
                                </h6>
                                <h6 className="text-dark">
                                  ₦
                                  {formatCurrency(
                                    tempData.wallets.marketplace_wallet
                                  )}
                                </h6>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody>
                            <Row>
                              <Col lg="3" xs="3">
                                <h1>
                                  <i className="fas fa-wallet text-dark"></i>
                                </h1>
                              </Col>
                              <Col xs="9">
                                <h6 className=" text-uppercase mt-0 small">
                                  Kuda Balance
                                </h6>
                                <h6 className="text-dark mt-0">
                                  ₦{formatCurrency(tempData.wallets.KUDA)}
                                </h6>
                                <h6 className=" text-uppercase mt-3 small">
                                  Accessbank Balance
                                </h6>
                                <h6 className="text-dark mt-0">
                                  ₦{formatCurrency(tempData.wallets.ACCESS)}
                                </h6>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody>
                            <Row>
                              <Col lg="3" xs="3">
                                <h1>
                                  <i className="fas fa-wallet text-dark"></i>
                                </h1>
                              </Col>
                              <Col xs="9">
                                <h6 className=" text-uppercase mt-0 small">
                                  Monnify Balance
                                </h6>
                                <h6 className="text-dark mt-0">
                                  ₦{formatCurrency(tempData.wallets.MONNIFY)}
                                </h6>
                                <h6 className=" text-uppercase mt-3 small">
                                  Flutterwave Balance
                                </h6>
                                <h6 className="text-dark mt-0">
                                  ₦
                                  {formatCurrency(tempData.wallets.FLUTTERWAVE)}
                                </h6>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody className="bg-primary text-white">
                            <p>DataNow Wallet</p>
                            <h5>
                              {" "}
                              ₦
                              {formatCurrency(
                                tempData.wallets.datanow_wallet_balance
                              )}
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody className="bg-success text-white">
                            <p>Users Wallet</p>
                            <h5>
                              {" "}
                              ₦
                              {formatCurrency(
                                tempData.wallets.users_wallet_balance_negative
                              )}
                              , ₦
                              {formatCurrency(
                                tempData.wallets.users_wallet_balance_positive
                              )}
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody className="bg-warning text-white">
                            <p>Businesses Wallet</p>
                            <h5>
                              {" "}
                              ₦
                              {formatCurrency(
                                tempData.wallets.business_wallet_balance
                              )}
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="3" xs="6">
                        <Card className="mini-stat  shadow">
                          <CardBody className="bg-secondary text-white">
                            <p>Unclaimed AutoAgent Funds</p>
                            <h5>
                              {" "}
                              ₦
                              {formatCurrency(
                                tempData.wallets.autoagent_wallet
                              )}
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th data-priority="3">Stat</th>
                            <th data-priority="3"> Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <b className="text-primary">
                                Charged MTN Data Balance
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets.charged_MTN_data_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged GLO Data Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets.charged_GLO_data_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged AIRTEL Data Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_AIRTEL_data_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged ETISALAT Data Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_ETISALAT_data_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-primary">
                                Charged MTN Airtime Balance
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_MTN_airtime_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged GLO Airtime Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_GLO_airtime_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged AIRTEL Airtime Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_AIRTEL_airtime_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Charged ETISALAT Airtime Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.wallets
                                  .charged_ETISALAT_airtime_wallet_balance
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                  </React.Fragment>
                )}

                {tempData.statistics && (
                  <React.Fragment>
                    <div className="mb-2">
                      <p className="text-uppercase text-left mb-0 ">
                        <b>DataNow Statistics</b>
                      </p>
                      <hr className="border-primary" />
                    </div>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing {tempData.statistics.length} Stats
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th data-priority="3">Stat</th>
                            <th data-priority="3"> Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <b className="text-primary">
                                Coporate Data Wallet Balance
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.corporate_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-primary">
                                DataNow VTPass Wallet Balance
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.datanow_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Business Withdrawals</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.business_withdrawals
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Accumulated Withdrawal Charges</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.business_withdrawals_charges
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-success">
                                Calculated DataNow Balance
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.calculated_datanow_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-danger">
                                Total Money Received Through Monnify
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics
                                  .total_received_through_datanow
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-primary">
                                Total Business Wallet Funds Debit
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.business_funds_sent
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Business Wallet Funds Credit</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.business_funds_received
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Calculated Business Wallet Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics
                                  .calculated_business_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Actual Business Wallet Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.business_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <b className="text-primary">
                                Total Customer Wallet Funds Debit
                              </b>
                            </th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.users_funds_sent
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Customer Wallet Funds Credit</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.users_funds_received
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Calculated Customer Wallet Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics
                                  .calculated_users_wallet_balance
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Actual Customer Wallet Balance</th>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.users_wallet_balance
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
