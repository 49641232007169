import React, { useContext, useEffect } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
} from "reactstrap";

import { AvForm } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Auto Agent Transactions-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const transactionSelect = (transaction) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "editStatus", value: transaction.status },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "description", value: "" },
    });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transaction", value: transaction },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: true },
    });
  };

  const handleStatusSubmit = (e) => {
    e.persist();
    e.preventDefault();
    changeTransactionStatus();
  };

  const toggleFilter = (e) => {
    e.preventDefault();

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "showFilter", value: !tempData.showFilter },
    });
  };

  const navigateToPage = (page) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transactions", value: false },
    });
    fetchTransactions(page);
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  useEffect(() => {
    if (!tempData.subscriptionLoaded) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "subscriptionLoaded", value: true },
      });
    }
  }, []);

  const transactionStatus = [
    "Credited",
    "Pending",
    "Reversed",
    "Short Funded",
    "Reference not found",
    "Bad Reference Code",
    "Bad Business Reference",
    "Bad User Reference",
    "Reference Mismatch",
    "Admin Cancelled",
    "MergedWithUSSD",
    "Pending USSD ",
  ];

  const transactionType = ["Unkown", "Business", "User"];

  const getBusinessName = (businessId) => {
    var businessName = "";
    businessData.businesses.map((business) => {
      if (business.business_id == businessId) businessName = business.name;
    });
    return businessName;
  };

  const Transaction = (props) => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {props.transaction.status * 1 !== 0 &&
              props.transaction.status * 1 !== 10 &&
              props.transaction.status * 1 !== 11 && (
                <Label
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    transactionSelect(props.transaction, true);
                  }}
                  className="text-danger"
                >
                  Fix Reference
                </Label>
              )}
          </td>
          <th>
            {props.transaction.status * 1 === 1 && (
              <label className="badge   badge-primary">
                {transactionStatus[props.transaction.status]}
              </label>
            )}
            {(props.transaction.status * 1 === 0 ||
              props.transaction.status * 1 == 10) && (
              <label className="badge   badge-success">
                {transactionStatus[props.transaction.status]}
              </label>
            )}
            {props.transaction.status * 1 > 1 &&
              props.transaction.status * 1 !== 10 && (
                <label className="badge   badge-danger">
                  {transactionStatus[props.transaction.status]}
                </label>
              )}
          </th>

          <td>{transactionType[props.transaction.type]}</td>
          <td>{props.transaction.reference}</td>
          <td>₦{formatCurrency(props.transaction.amount)}</td>
          <td>₦{formatCurrency(props.transaction.amount_paid)}</td>
          <td>{props.transaction.description}</td>
          <td>{props.transaction.date}</td>
          <td>{props.transaction.bank}</td>
          <td>{props.transaction.account_number}</td>
          <td>{props.transaction.account_name}</td>
          <td>{getBusinessName(props.transaction.business_id)}</td>
          <td>{props.transaction.user_id}</td>
          <td>{props.transaction.created_at}</td>
        </tr>
      </React.Fragment>
    );
  };

  const changeTransactionStatus = () => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Changing status...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("status", String(formData.editStatus));
    urlencoded.append("description", String(formData.description));

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    var url =
      localStorage.getItem("apiURL") + "auto_agent/" + tempData.transaction.id;

    fetch(url, requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "transactions",
              value: tempData.transactions.map((transaction) => {
                if (transaction.id === tempData.transaction.id)
                  return data.data;
                return transaction;
              }),
            },
          });
        } else if (data.message === "Token Expired") {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "modalOpen", value: true },
        });
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: true },
        });
      });
  };

  const fetchTransactions = (page) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving transactions...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.transactionStatus
      ? "&status=" + formData.transactionStatus
      : "";
    parameter += formData.transactionType
      ? "&type=" + formData.transactionType
      : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "auto_agent?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          console.log(objectValues);
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "transactions", value: objectValues },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "current_page", value: data.data.current_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "last_page", value: data.data.last_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "per_page", value: data.data.per_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "total", value: data.data.total },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const retrieveAutoAgentTransactions = () => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "modalOpen", value: false },
    });
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving Transactions...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "startDate",
      String(formData.dateFrom ? formData.dateFrom : "")
    );
    urlencoded.append(
      "endDate",
      String(formData.dateTo ? formData.dateTo : "")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "auto_agent", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: "",
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="12" ml="12" sm="12" className="  ">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Auto Agent Transactions</b>
                    {businessData.staff.clearance_level === 0 && (
                      <>
                        {" "}
                        <Button
                          className="btn-danger btn ml-2"
                          onClick={retrieveAutoAgentTransactions}
                        >
                          Revalidate Transactions in the Date Range (must be a
                          day interval to avoid error)
                        </Button>
                        {formData.error && (
                          <Alert color="danger">{formData.error}</Alert>
                        )}
                      </>
                    )}
                  </p>
                  <hr className="border-primary" />
                </div>
                <Link onClick={toggleFilter} className="mb-0 pb-0">
                  {!tempData.showFilter ? (
                    <p className="text-uppercase text-left mb-0 small">
                      <i className="mdi mdi-filter "></i>Show Filters{" "}
                    </p>
                  ) : (
                    <p className="text-uppercase text-left mb-0 small">
                      <i className="mdi mdi-filter-remove "></i> Hide Filters
                    </p>
                  )}
                </Link>
                <AvForm
                  className="mt-2"
                  role="search"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    {tempData.showFilter && (
                      <React.Fragment>
                        <Col md="3" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transactionStatus}
                            name="transactionStatus"
                            onChange={formOnChange}
                          >
                            <option value="">All Transactions</option>
                            {transactionStatus.map((transaction, index) => {
                              return (
                                <option value={index}>{transaction}</option>
                              );
                            })}
                          </select>
                        </Col>

                        <Col md="2" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transactionType}
                            name="transactionType"
                            onChange={formOnChange}
                          >
                            <option value="">All Types</option>
                            {transactionType.map((transaction, index) => {
                              return (
                                <option value={index}>{transaction}</option>
                              );
                            })}
                          </select>
                        </Col>

                        <Col md="2" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.pageSize}
                            name="pageSize"
                            onChange={formOnChange}
                          >
                            <option value="">15 per page</option>
                            <option value="30">30 per page</option>
                            <option value="50">50 per page</option>
                            <option value="100">100 per page</option>
                          </select>
                        </Col>

                        <Col md="2" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="dateFrom"
                            value={formData.dateFrom}
                            onChange={formOnChange}
                          />
                        </Col>
                        <label className="mt-2">To</label>
                        <Col md="2" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="dateTo"
                            value={formData.dateTo}
                            onChange={formOnChange}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    <div className=" mb-0 ml-3 app-search ">
                      <input
                        type="text"
                        className="form-control bg-light text-dark shadow"
                        placeholder="Search.."
                        name="search"
                        value={formData.search}
                        onChange={formOnChange}
                      />
                      <button style={{ marginTop: -12 }} type="submit">
                        <i className="mdi mdi-magnify  mdi-24px text-primary "></i>
                      </button>
                    </div>
                  </Row>
                </AvForm>

                {tempData.transactions && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing (
                      {tempData.per_page * tempData.current_page -
                        tempData.per_page +
                        1}{" "}
                      to{" "}
                      {tempData.current_page * tempData.per_page -
                        tempData.per_page +
                        tempData.transactions.length}{" "}
                      ) of {tempData.total} Transactions
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th data-priority="3"></th>
                            <th>Status</th>
                            <th data-priority="1">Type</th>
                            <th data-priority="1">Reference</th>
                            <th data-priority="3">Amount Paid</th>
                            <th data-priority="3">Amount Credited</th>
                            <th data-priority="3">Description</th>
                            <th data-priority="3">Posting Date</th>
                            <th data-priority="3">Bank</th>
                            <th data-priority="3">Account Number</th>
                            <th data-priority="3">Account Name</th>
                            <th data-priority="1">Business</th>
                            <th data-priority="3">User ID</th>
                            <th data-priority="3">Server Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.transactions.map((transaction) => {
                            return (
                              <Transaction
                                key={transaction.id}
                                transaction={transaction}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}

                {tempData.transactions && (
                  <React.Fragment>
                    <hr className="mt-0" />
                    <p className="text-uppercase text-left mb-0 small">
                      Showing page {tempData.current_page} of{" "}
                      {tempData.last_page}
                    </p>
                    <Row className="ml-1 mt-2 ">
                      <Pagination className="pagination-sm">
                        <PaginationItem
                          disabled={tempData.current_page > 1 ? false : true}
                        >
                          <PaginationLink
                            first
                            onClick={() => {
                              navigateToPage(tempData.current_page - 1);
                            }}
                          />
                        </PaginationItem>
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page - 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page - 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem active>
                          <PaginationLink>
                            {tempData.current_page}
                          </PaginationLink>
                        </PaginationItem>

                        {tempData.last_page >= tempData.current_page + 5 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page + 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page + 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem
                          disabled={
                            tempData.current_page === tempData.last_page
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            onClick={() => {
                              navigateToPage(tempData.current_page + 1);
                            }}
                            last
                          />
                        </PaginationItem>
                      </Pagination>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {tempData.transaction && (
        <Modal isOpen={tempData.modalOpen}>
          <ModalBody>
            <div className="mb-2 text-dark">
              <button
                type="button"
                className="close"
                onClick={() => {
                  tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: "modalOpen", value: false },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="text-uppercase text-left mb-0 ">
                <b>Transaction details</b>
              </p>

              <hr className="border-primary" />
              <Row style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>TRANSACTION STATUS</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">
                    {transactionStatus[tempData.transaction.status]}
                  </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>AMOUNT PAID</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">
                    ₦{formatCurrency(tempData.transaction.amount)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>AMOUNT CREDITED</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">
                    ₦{formatCurrency(tempData.transaction.amount_paid)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>REFERENCE</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">{tempData.transaction.reference}</p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>DESCRIPTION</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">{tempData.transaction.description}</p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>POSTING DATE</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">{tempData.transaction.posting_date}</p>
                </Col>
              </Row>
              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>USER ID</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">{tempData.transaction.user_id}</p>
                </Col>
              </Row>

              <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                <Col xs="6">
                  <b>SERVER DATE</b>
                </Col>
                <Col xs="6">
                  <p className="m-0 p-0">{tempData.transaction.created_at}</p>
                </Col>
              </Row>

              <AvForm
                className="mt-2"
                role="search"
                onValidSubmit={handleStatusSubmit}
              >
                <hr />
                <Row className="mt-2 mb-2" style={{ fontSize: 12 }}>
                  <Col xs="6">
                    <b>Enter New Reference Code</b>
                  </Col>
                </Row>
                {formData.error && (
                  <Alert color="danger">{formData.error}</Alert>
                )}

                <div md="3" xs="6" className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control bg-light text-dark shadow"
                    placeholder="Enter Reference Code"
                    name="description"
                    value={formData.description}
                    onChange={formOnChange}
                  />
                </div>

                <Button
                  className="btn-danger w-md waves-effect waves-light mb-2"
                  type="submit"
                >
                  Change Status
                </Button>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
