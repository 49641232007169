import React, {  useEffect, useState , useContext} from 'react';
import { Link } from 'react-router-dom';
import logoLight from '../../images/logo-light.png';
import MenuItem from './MenuItem';
import MenuItemWithChildren from './MenuItemWithChildren';
import MenuWithMega from './MenuWithMega';
import {
    
    BusinessDataContext
  } from "../../contexts/Contexts";
import data from './menuList';
 
const Topbar =(props)=>{
  const [is_large_state, toggleState]=  useState (false)
  const { businessData } = useContext(BusinessDataContext);  

   const toggleMenu=()=>
    {
    toggleState(!is_large_state);
    }
    
    const toggleFullscreen=()=> {
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

   
    useEffect (()=>{
        var width = window.innerWidth;
        if (width >= 768) { }
        else { toggleMenu(is_large_state); }

        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (window.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }

        
    }, [])

     
   const activateParentDropdown = (item) => {

        item.classList.add('active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('active'); // li 
            const parent2 = parent.parentElement;

            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add('active'); // li 
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add('active'); // li 
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add('active'); // li 
                    }
                }
            }
        }
        return false;
    }

         return (
            <React.Fragment>
                <header id="topnav">
                    <div className="topbar-main m-0 p-0  bg-primary">
                        <div className="container-fluid ml-2 p-0">
                            <div className="logo">
                                <Link to="/" className="logo">
                                    <img src={logoLight} alt="" style={{height:'28px'}}  className="logo-small ml-2 mt-2" />
                                    <img src={logoLight} alt=""style={{height:'28px'}}   className="logo-large  ml-2 mt-2" />
                                </Link>
                            </div>
                            <div className="menu-extras topbar-custom bg-danger ">
                                <ul className="navbar-right list-inline float-right mb-0  ">
        
        <li className="dropdown notification-list list-inline-item   mr-1 mt-2 text-white">
        <h6> <i  className="mdi mdi-account-circle"></i> {businessData.staff && businessData.staff.name}</h6>
                                    </li>   <li className="dropdown notification-list list-inline-item d-none d-md-inline-block mr-1">
                                        <Link onClick={toggleFullscreen} className="nav-link" to="#" id="btn-fullscreen">
                                            <i className="mdi mdi-fullscreen noti-icon"></i>
                                        </Link>
                                    </li> 
                                    <li  className="menu-item list-inline-item ">
                                        <span  className={is_large_state ? "navbar-toggle nav-link" : "navbar-toggle nav-link open"}>
                                            <div onClick={toggleMenu} className="lines">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="navbar-custom bg-primary">
                        <div className="container-fluid  text-center ">

                            <div id="navigation" style={{ display: is_large_state ? "none" : "block" }}>
                                <ul className="navigation-menu ">
                                    {data.map((item, i) => {
                                        if (item.is_mega) {
                                            return <MenuWithMega item={item} key={i} />
                                        }
                                        else {
                                            if (item.children)
                                                return <MenuItemWithChildren item={item} key={i} />
                                            else
                                                return <li key={i} className="has-submenu"> <MenuItem item={item} /></li>
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }

 
export default  (Topbar);

