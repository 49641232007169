import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "DataNow Wallet Transactions-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const toggleFilter = (e) => {
    e.preventDefault();

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "showFilter", value: !tempData.showFilter },
    });
  };

  const navigateToPage = (page) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "transactions", value: false },
    });
    fetchTransactions(page);
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  useEffect(() => {
    if (!tempData.subscriptionLoaded) {
      fetchTransactions(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "subscriptionLoaded", value: true },
      });
    }
  }, []);

  const transactionStatus = [
    "Successful",
    "Pending",
    "Insufficient Fund",
    "Admin Cancelled",
  ];

  const transactionType = [
    "Customer Cable Purchase",
    "Customer Cable Purchase VAT",
    "Customer Electric Purchase",
    "Customer Electric Purchase VAT",
    "Coporate Data Purchase",
    "AutoAgent Commission ",
    "AutoAgent Commission VAT",
    "Merchant Withdrawal Charge",
    "Merchant Withdrawal Charge VAT",
    "Merchant Corporate Data Charge",
    "Merchant Corporate Data Charge VAT",
    "Admin User Transaction",
    "Admin Business Transaction",
    "DataNow Revenue Transaction",
    "Merchant Market Place Purchase",
    "Customer / Business Airtime Purchase",
  ];

  const getBusinessName = (businessId) => {
    var businessName = "";
    businessData.businesses.map((business) => {
      if (business.business_id == businessId) businessName = business.name;
    });
    return businessName;
  };

  const Transaction = (props) => {
    return (
      <React.Fragment>
        <tr>
          <th>
            {props.transaction.status * 1 === 1 && (
              <label className="badge   badge-primary">
                {transactionStatus[props.transaction.status]}
              </label>
            )}
            {props.transaction.status * 1 === 0 && (
              <label className="badge   badge-success">
                {transactionStatus[props.transaction.status]}
              </label>
            )}
            {props.transaction.status * 1 > 1 && (
              <label className="badge   badge-danger">
                {transactionStatus[props.transaction.status]}
              </label>
            )}
          </th>

          <td>{transactionType[props.transaction.transaction_type]}</td>
          <td>₦{formatCurrency(props.transaction.amount)}</td>
          <td>₦{formatCurrency(props.transaction.amount_before)}</td>
          <td>₦{formatCurrency(props.transaction.amount_after)}</td>
          <td>{getBusinessName(props.transaction.business_id)}</td>
          <td>{props.transaction.payer}</td>
          <td>{props.transaction.receiver}</td>
          <td>{props.transaction.description}</td>
          <td>{props.transaction.created_at}</td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchTransactions = (page) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving transactions...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.transactionStatus
      ? "&status=" + formData.transactionStatus
      : "";
    parameter += formData.transactionType
      ? "&transaction_type=" + formData.transactionType
      : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") +
        "datanow_wallet_transactions?" +
        parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          console.log(objectValues);
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "transactions", value: objectValues },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "current_page", value: data.data.current_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "last_page", value: data.data.last_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "per_page", value: data.data.per_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "total", value: data.data.total },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>DataNow Wallet Transactions</b>
                  </p>
                  <hr className="border-primary" />
                </div>
                <Link onClick={toggleFilter} className="mb-0 pb-0">
                  {!tempData.showFilter ? (
                    <p className="text-uppercase text-left mb-0 small">
                      <i className="mdi mdi-filter "></i>Show Filters{" "}
                    </p>
                  ) : (
                    <p className="text-uppercase text-left mb-0 small">
                      <i className="mdi mdi-filter-remove "></i> Hide Filters
                    </p>
                  )}
                </Link>
                <AvForm
                  className="mt-2"
                  role="search"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    {tempData.showFilter && (
                      <React.Fragment>
                        <Col md="3" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transactionStatus}
                            name="transactionStatus"
                            onChange={formOnChange}
                          >
                            <option value="">All Transactions</option>
                            {transactionStatus.map((transaction, index) => {
                              return (
                                <option value={index}>{transaction}</option>
                              );
                            })}
                          </select>
                        </Col>

                        <Col md="2" xs="6" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.transactionType}
                            name="transactionType"
                            onChange={formOnChange}
                          >
                            <option value="">All Types</option>
                            {transactionType.map((transaction, index) => {
                              return (
                                <option value={index}>{transaction}</option>
                              );
                            })}
                          </select>
                        </Col>

                        <Col md="2" xs="12" className="form-group mb-2">
                          <select
                            className="form-control"
                            value={formData.pageSize}
                            name="pageSize"
                            onChange={formOnChange}
                          >
                            <option value="">15 per page</option>
                            <option value="30">30 per page</option>
                            <option value="50">50 per page</option>
                            <option value="100">100 per page</option>
                          </select>
                        </Col>

                        <Col md="2" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="dateFrom"
                            value={formData.dateFrom}
                            onChange={formOnChange}
                          />
                        </Col>
                        <label className="mt-2">To</label>
                        <Col md="2" xs="5" className="form-group mb-2">
                          <input
                            type="date"
                            className="form-control"
                            name="dateTo"
                            value={formData.dateTo}
                            onChange={formOnChange}
                          />
                        </Col>
                      </React.Fragment>
                    )}

                    <div className=" mb-0 ml-3 app-search ">
                      <input
                        type="text"
                        className="form-control bg-light text-dark shadow"
                        placeholder="Search.."
                        name="search"
                        value={formData.search}
                        onChange={formOnChange}
                      />
                      <button style={{ marginTop: -12 }} type="submit">
                        <i className="mdi mdi-magnify  mdi-24px text-primary "></i>
                      </button>
                    </div>
                  </Row>
                </AvForm>

                {tempData.transactions && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing (
                      {tempData.per_page * tempData.current_page -
                        tempData.per_page +
                        1}{" "}
                      to{" "}
                      {tempData.current_page * tempData.per_page -
                        tempData.per_page +
                        tempData.transactions.length}{" "}
                      ) of {tempData.total} Transactions
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th data-priority="1">Type</th>
                            <th data-priority="1">Amount</th>
                            <th data-priority="3">Amount Before</th>
                            <th data-priority="3">Amount After</th>
                            <th data-priority="3">Business</th>
                            <th data-priority="3">Payer</th>
                            <th data-priority="3">Receiver</th>
                            <th data-priority="3">Description</th>
                            <th data-priority="3">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.transactions.map((transaction) => {
                            return (
                              <Transaction
                                key={transaction.id}
                                transaction={transaction}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}

                {tempData.transactions && (
                  <React.Fragment>
                    <hr className="mt-0" />
                    <p className="text-uppercase text-left mb-0 small">
                      Showing page {tempData.current_page} of{" "}
                      {tempData.last_page}
                    </p>
                    <Row className="ml-1 mt-2 ">
                      <Pagination className="pagination-sm">
                        <PaginationItem
                          disabled={tempData.current_page > 1 ? false : true}
                        >
                          <PaginationLink
                            first
                            onClick={() => {
                              navigateToPage(tempData.current_page - 1);
                            }}
                          />
                        </PaginationItem>
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page - 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page - 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem active>
                          <PaginationLink>
                            {tempData.current_page}
                          </PaginationLink>
                        </PaginationItem>

                        {tempData.last_page >= tempData.current_page + 5 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page + 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page + 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem
                          disabled={
                            tempData.current_page === tempData.last_page
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            onClick={() => {
                              navigateToPage(tempData.current_page + 1);
                            }}
                            last
                          />
                        </PaginationItem>
                      </Pagination>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
