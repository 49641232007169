import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "Marketplace-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (dataServer,fund=false) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'fund', value: fund },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'id', value: dataServer.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'price', value: dataServer.price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: dataServer.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'network', value: dataServer.network },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'type', value: dataServer.type },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'operation', value:'ADD' },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }

  
 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("status", String(formData.status));
    urlencoded.append("price", String(formData.price));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("type", String(formData.type));
    urlencoded.append("operation", String(formData.operation));
    urlencoded.append("amount", String(formData.amount));
    

    var requestOptions = {
      method:formData.fund ? "POST" : formData.id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
   formData.fund?   localStorage.getItem("apiURL") + "fund_airtime_stock/"+formData.id  :
   formData.id ?  localStorage.getItem("apiURL") + "airtime_stock/"+formData.id  : localStorage.getItem("apiURL") + "airtime_stock"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        formData.id ? tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "dataServers", value:tempData.dataServers.map((dataServer) => {
            if(data.data.id===dataServer.id) return {...dataServer, ...data.data}
            return dataServer;
           } 
         )}
          }) : 
          fetchDataPlans(1);

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.dataServersLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'dataServersLoaded', value: true },
      });
    }
  }, []);
    
    
  const DataServer = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.dataServer.status*1 === 0 && (<label className="badge   badge-success">
        Available</label>) }
        {props.dataServer.status*1 === 1 && (<label className="badge text-dark  badge-warning">
        Out of Stock</label>) }
      </td>
             <td   >{props.dataServer.network}</td>
             <td   >{props.dataServer.type*1===0? 'Data Airtime' : 'VTU Airtime'}</td>
             <td   >{props.dataServer.price}%</td>
             <td   >₦{formatCurrency(props.dataServer.amount_remaining)} </td>
             <td className="text-success" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.dataServer,true)}} >Fund Stock</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.dataServer)}} >Edit</td>

             </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving stocks...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "airtime_stock" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "dataServers", value: data.data },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "dataServersMaster", value: data.data },
                    });
                  
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Marketplace Stocks</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,network:'MTN', type:'0'})
                }}><i className="mdi mdi-plus"></i> Add Stock</b></p>
                <hr className="border-primary" />
                </div>
               
                  {tempData.dataServers && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.dataServers.length} Data Servers</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                     
                      <th data-priority="1">Status</th>
                      <th data-priority="1">Network</th>
                      <th data-priority="1">Type</th>
                      <th data-priority="1">Price</th>
                       <th data-priority="1">Amount Remaining</th>
                       <th data-priority="1"></th>
                       <th data-priority="1"></th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.dataServers.map((dataServer) => {
                        return <DataServer key={dataServer.id} dataServer={dataServer} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.dataServers && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
              
                  <p className="text-uppercase text-left mb-0 " ><b>{formData.fund? 'Fund '+formData.network+' '+formData.type : formData.id ? 'Edit Stock': 'Add Stock'}</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
                >
                 { formData.fund ? 
                  <React.Fragment>
                    <p className="  text-left mb-0 mt-2 ">
                     <b>Operation</b>
                </p>
                  <select className="form-control" value={formData.operation} name="operation" onChange={(e)=>{formOnChange(e);} }>
                  <option value="ADD">CREDIT</option>
                  <option value="SUB">DEBIT</option>
                  </select>

                  
                  <AvField
                  name="amount"
                  label="Amount"
                  value={formData.amount}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Amount"
                  type="text"
                 />

<AvField
      label="price"
      name="price"
      value={formData.price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Price"
      type="text"
      errorMessage="Enter valid price"
      required
      />
                
              </React.Fragment>
                
                 : 
                
               (!formData.id ? 
                   <React.Fragment>
                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Network</b>
                </p>
                  <select className="form-control" value={formData.network} name="network" onChange={(e)=>{formOnChange(e);} }>
                  <option value="MTN">MTN</option>
                  <option value="GLO">GLO</option>
                  <option value="AIRTEL">AIRTEL</option>
                  <option value="ETISALAT">ETISALAT</option>
                  </select>

                <p>
                  <b>Type</b>
                </p>
                  <select className="form-control" value={formData.type} name="type" onChange={(e)=>{formOnChange(e);} }>
                  <option value="0">Data Airtime</option>
                  <option value="1">VTU Airtime</option>
                   </select>
                </React.Fragment>
                  :
          <React.Fragment>
        <p className="  text-left mb-0 mt-2 ">
           <b>Status</b>
        </p>
          <select className="form-control" value={formData.status} name="status" onChange={(e)=>{formOnChange(e);} }>
          <option value="0">Available</option>
          <option value="1">Out of Stock</option>
         </select>
        
        
    <AvField
      label="price"
      name="price"
      value={formData.price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Price"
      type="text"
      errorMessage="Enter valid price"
      required
      />
       </React.Fragment>
      
               )
               }     
              
               <Button
                  className="btn-primary w-md waves-effect waves-light mt-2"
                  type="submit"
                >
                  {formData.id ? 'Save Changes': 'Add Stock'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        