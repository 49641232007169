import React, { useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import { withRouter } from "react-router-dom";

import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  if (!props.summary) document.title = "Business Transactions-DataNow";

  useEffect(() => {
    if (!tempData.expectedStatLoaded) {
      fetchExpectedStat();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "expectedStatLoaded", value: true },
      });
    }
  }, []);

  const fetchExpectedStat = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving stats...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "expected_server_statistics?",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "exptectedStatistics", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center  align-content-center ">
          <Col
            xl={props.summary ? "12" : "10"}
            ml={props.summary ? "12" : "10"}
            sm="12"
            className=" p-0 m-0"
          >
            <Card className="mini-stat text-dark shadow  ">
              <CardBody className=" ">
                {tempData.exptectedStatistics && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Mobile Server Statistics
                    </p>
                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th></th>
                            <th>MTN Data Airtime</th>
                            <th data-priority="3">Glo Data Airtime</th>
                            <th data-priority="3">Airtel Data Airtime</th>
                            <th data-priority="3">Etisalat Data Airtime</th>
                            <th>MTN VTU Airtime</th>
                            <th data-priority="3">Glo VTU Airtime</th>
                            <th data-priority="3">Airtel VTU Airtime</th>
                            <th data-priority="3">Etisalat VTU Airtime</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>Expected Values</b>
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.MTN_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.GLO_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.AIRTEL_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .ETISALAT_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.MTN_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.GLO_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics.AIRTEL_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .ETISALAT_vtu_airtime
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <b>Actual Values</b>
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .MTN_data_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .GLO_data_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .AIRTEL_data_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .ETISALAT_data_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .MTN_vtu_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .GLO_vtu_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .AIRTEL_vtu_airtime_actual
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.exptectedStatistics
                                  .ETISALAT_vtu_airtime_actual
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <b>Comments</b>
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .MTN_data_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .GLO_data_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .AIRTEL_data_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .ETISALAT_data_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .MTN_vtu_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .GLO_vtu_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .AIRTEL_vtu_airtime_comment
                              }
                            </td>
                            <td>
                              {
                                tempData.exptectedStatistics
                                  .ETISALAT_vtu_airtime_comment
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}

                {tempData.statistics && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing Mobile Server Statistics
                    </p>
                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th>MTN Data Airtime</th>
                            <th data-priority="1">MTN SME Data</th>
                            <th data-priority="3">Glo Data Airtime</th>
                            <th data-priority="3">Airtel Data Airtime</th>
                            <th data-priority="3">Etisalat Data Airtime</th>
                            <th>MTN VTU Airtime</th>
                            <th data-priority="3">Glo VTU Airtime</th>
                            <th data-priority="3">Airtel VTU Airtime</th>
                            <th data-priority="3">Etisalat VTU Airtime</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.MTN_data_airtime
                              )}
                            </td>
                            <td>
                              {formatCurrency(tempData.statistics.MTN_sme_data)}
                              MB
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.GLO_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.AIRTEL_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.ETISALAT_data_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.MTN_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.GLO_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.AIRTEL_vtu_airtime
                              )}
                            </td>
                            <td>
                              ₦
                              {formatCurrency(
                                tempData.statistics.ETISALAT_vtu_airtime
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
