import React, { useContext } from "react";
import { Alert, Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  if (!props.summary) document.title = "VTPass Transactions -DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions();
  };

  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Submitting Request...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("reference", String(formData.reference));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "vtpass_webhook", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log("data received:" + data);
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: {
            name: "responseModal",
            value: { isOpen: true, text: response },
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center  align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow  ">
              <CardBody className=" ">
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Check VTPass Transaction via Webhook</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <Row className="pl-2">
                  {/* ######################################################### */}
                  <Col xs="5" className="shadow m-1 p-1">
                    <p>
                      <b>VTPass Payment ID</b>
                    </p>
                    <AvForm
                      className="mt-2"
                      role="search"
                      onValidSubmit={(e) => handleSubmit(e)}
                    >
                      <Row>
                        <Col md="8" xs="8" className="form-group mb-2">
                          <AvField
                            name="reference"
                            label="Payment Reference"
                            value={formData.reference}
                            onChange={(e) => {
                              formOnChange(e);
                            }}
                            placeholder="Enter VTPass API ID"
                            type="text"
                            required
                          />
                        </Col>
                      </Row>

                      {formData.error && (
                        <Alert color="danger">{formData.error}</Alert>
                      )}

                      <div>
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify  text-white " />
                          Submit
                        </button>
                      </div>
                    </AvForm>
                  </Col>

                  {/* #################################################################### */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
