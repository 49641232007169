import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card ,Collapse, CardBody,CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from '../../images/logo-dark.png'
import logo from '../../images/logo-sm.png'
import wave from '../../images/wave.svg'
import ResponseModal from "../../components/ResponseModal";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
 } from "../../contexts/Contexts";

const SignIn = (props) => {

    const { loaderDispatch } = useContext(ModalContext);
    const {   tempData, tempDataDispatch } = useContext(TempDataContext);
    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

    document.title='DataNow Admin';

    businessData.staff && props.history.push("/dashboard");
    
    const formOnChange = (e) => {
        tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: e.target.name, value: e.target.value },
        });
    };
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
         loaderDispatch({
            type: "SET_LOADER",
            data: { text: "signing in...", isLoading: true },
        });
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        var urlencoded = new URLSearchParams();
        urlencoded.append("identifier", String(tempData.identifier));
        urlencoded.append("password", String(tempData.password));
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
        };
        fetch(
            localStorage.getItem('apiURL')+"signin",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                if (data.status === "success") {
                    console.log(data)
                tempDataDispatch({
                    type: "SET_FORM_DATA", 
                    data: { name: 'dataLoaded', value:true },
                  }); 
                   businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...data.data,token:data.token}})
               
                } else {
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: data.message, 
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
       
        return (
            <React.Fragment>
                <div  style={{position:'relative'}}>
                <img className=" mt-0" src={wave} style={{width:'100%',bottom:'0',position:'absolute'}} alt=""/>
                <p className="text-center  pt-4">
                   <img src={logoBg} alt="" height="36" className="float-left mr-3 ml-3"/>
                    </p>
                    <br/>
                <Row className="mt-4">
                    <Col lg="7"  >
                        <Row    >
                            <Col lg="2" ></Col>
                             <Col lg="8"  ><h1 className="m-3  text-dark">At Systems Technologies,</h1>
                             <h5 className="m-3">It's all about People & Solutions</h5>
                              </Col>
                            </Row>
                            </Col>
              <Col lg="5" className=" mb-0 ">
                  <div className="wrapper-page">
            <Card className="overflow-hidden account-card mx-3 shadow ">
            <div className=" p-4 text-white text-center position-relative bg-primary">
        <h4 className="font-20 m-b-5 mb-4">Sign In</h4>
            <Link to="/" className="logo logo-admin">
            <img src={logo} height="36" alt="logo" />
            </Link>
            </div>
            <div className="account-card-content">
            {tempData.error && <Alert color="danger">{tempData.error}</Alert>}
            <AvForm
            className="form-horizontal m-t-30"
            onValidSubmit={handleSubmit}
            >
            <AvField
            name="identifier"
            label="Email or Phone Number"
            value={tempData.identifier}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Email or Phone Number"
            type="text"
            required
            />
            <AvField
            name="password"
            label="Password"
            value={tempData.password}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Enter Password"
            type="password"
            required
            />
            
            <Row className="form-group m-t-20">
            <Col sm="6"></Col>
            <Col sm="6" className="text-right">
            <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="submit"
            >
            Sign In
            </Button>
            </Col>
            </Row>
            
            <Row className="form-group m-t-10 mb-0">
            <Col md="12" className="m-t-20">
            <Link to="/forgotPassword" >
            <i className="mdi mdi-lock"></i> Forgot your password?
            </Link>
            </Col>
            </Row>
            </AvForm>
            </div>
            </Card>
            </div>
            </Col>
            </Row>
            </div>
     
       
        
            <Row className="justify-content-center  text-white pt-3"   style={{backgroundColor:'#4E5CCF'}}  id="contact">
              <Col className="text-center m-3 align-self-center" xl="6">
             <h6><i className="mdi mdi-map-marker-check "></i>  No. 21 University Road, Tanke, Ilorin, Kwara State</h6>
             <h6><i className="mdi mdi-phone-in-talk "></i> Call Us : 08168670476</h6>
             <h6><i className="mdi mdi-email"></i> Email Us : support@systemstech.com.ng</h6>
             </Col>
             <Col xs="12">
                 <p style={{fontSize:16}} className="text-center pb-4">©{new Date().getFullYear()} Systems Technologies,  All rights reserved.</p>
             </Col>
             </Row>
 

            </React.Fragment>
            );
        };
        


        const Faq=(props)=>{
            const [open,setOpen]=useState(false)

            return (
                <Col xs="12">
                <Card className="mb-4 border rounded">
                <div  className="faq collapsed shadow border ">
                   <CardHeader className="text-dark" id="headingOne"   onClick={() => setOpen(!open)} style={{cursor:'pointer'}}>
                        <h6 className="m-0 faq-question">
                            <i className={open? "mdi mdi-arrow-up-drop-circle " : "mdi mdi-arrow-down-drop-circle "} 
                          ></i> {props.question}</h6>
                    </CardHeader>
                    </div>
                    <Collapse isOpen={open}>
                    <CardBody>
            <p className=" mb-0 faq-ans"> {props.answer}</p>
                    </CardBody>
                    </Collapse>
            </Card>
            </Col>
         
            )
        }

        
        const Solution=(props)=>{
            return (
                <Col md="6" >
                <Row className="m-4">
                         <div  className=" border border-white  mr-3 text-center align-content-center align-self-center">
                            <i style={{fontSize:70}} className={props.icon+ " m-3"}></i>
                        </div>
                     <Col md="7" className="m-0 p-0">
            <h5 className="text-uppercase " >{props.title}</h5>
                        <h6 >{props.text}</h6>
                    </Col>
                </Row>
            </Col>

            )
        }

        export default withRouter(SignIn);
        