import SignIn from "./pages/Auth/SignIn";
import SignOut from "./pages/Auth/SignOut";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import DataWalletTransactions from "./pages/Data/DataWalletTransactions";
import CablePlans from "./pages/Cable/CablePlans";
import ElectricPlans from "./pages/Electric/ElectricPlans";
import Customers from "./pages/Customers/Customers";
import CustomersTransactions from "./pages/Customers/CustomersTransactions";
import BusinessTransactions from "./pages/Business/BusinessTransactions";
import Withdrawals from "./pages/Business/Withdrawals";
import Profile from "./pages/Auth/Profile";
import Admin from "./pages/Admin/Admin";
import AppSettings from "./pages/Business/AppSettings";
import DataPlans from "./pages/Data/DataPlans";
import AirtimePlans from "./pages/Airtime/AirtimePlans";
import DataServer from "./pages/Data/DataServer";
import MTNSmePlans from "./pages/Data/MTNSmePlans";
import Businesses from "./pages/Business/Businesses";
import GeneralSettings from "./pages/Business/GeneralSettings";
import Bank from "./pages/Bank/Banks";
import Merchants from "./pages/Merchant/Merchants";
import Statistics from "./pages/Statistics/Statistics";
import ServerStatistics from "./pages/Statistics/ServerStatistics";
import AutoAgentTransactions from "./pages/Business/AutoAgentTransactions";
import Marketplace from "./pages/Marketplace/Marketplace";
import DataNowWalletTransactions from "./pages/Business/DataNowWalletTransactions";
import MarketPlaceTransactions from "./pages/Business/MarketPlaceTransactions";
import Accounts from "./pages/Statistics/Accounts";
import FlutterwaveWebhook from "./pages/Business/FlutterwaveWebhook";
import VTPass from "./pages/Business/VTPass";
import CustomerAdminWithdrawal from "./pages/Customers/CustomerAdminWithdrawal";
import DataNowAdminTransfer from "./pages/Business/DataNowAdminTransfer";
import DataNowWalletTransfer from "./pages/Business/DataNowWalletTransfer";
import Dashboard from "./pages/Dashboard/Dashboard";
import BusinessDataServer from "./pages/Data/BusinessDataServer";

const routes = [
  // public Routes
  { path: "/signin", component: SignIn, ispublic: true },
  { path: "/signout", component: SignOut, ispublic: true },
  { path: "/forgotPassword", component: ForgotPassword, ispublic: true },
  { path: "/resetPassword", component: ResetPassword, ispublic: true },

  // Dashnoard
  { path: "/dashboard", component: Dashboard, withLayout: true },

  {
    path: "/data-wallet-subscriptions",
    component: DataWalletTransactions,
    withLayout: true,
  },
  { path: "/data-plans", component: DataPlans, withLayout: true },
  { path: "/data-server", component: DataServer, withLayout: true },
  {
    path: "/business-data-server",
    component: BusinessDataServer,
    withLayout: true,
  },
  { path: "/mtn-sme-server", component: MTNSmePlans, withLayout: true },

  { path: "/airtime-plans", component: AirtimePlans, withLayout: true },
  { path: "/cable-plans", component: CablePlans, withLayout: true },
  { path: "/electric-plans", component: ElectricPlans, withLayout: true },
  { path: "/customers", component: Customers, withLayout: true },
  {
    path: "/customers-transactions",
    component: CustomersTransactions,
    withLayout: true,
  },
  {
    path: "/customer-transactions",
    component: CustomersTransactions,
    withLayout: true,
  },
  { path: "/businesses", component: Businesses, withLayout: true },
  {
    path: "/business-transactions",
    component: BusinessTransactions,
    withLayout: true,
  },
  { path: "/withdrawals", component: Withdrawals, withLayout: true },
  { path: "/settings", component: GeneralSettings, withLayout: true },
  { path: "/app-settings", component: AppSettings, withLayout: true },
  { path: "/banks", component: Bank, withLayout: true },
  { path: "/merchants", component: Merchants, withLayout: true },
  { path: "/profile", component: Profile, withLayout: true },
  { path: "/admins", component: Admin, withLayout: true },
  { path: "/statistics", component: Statistics, withLayout: true },
  { path: "/accounts", component: Accounts, withLayout: true },
  { path: "/server-statistics", component: ServerStatistics, withLayout: true },
  { path: "/transactions", component: CustomersTransactions, withLayout: true },
  { path: "/vtpass", component: VTPass, withLayout: true },
  {
    path: "/auto-agent-transactions",
    component: AutoAgentTransactions,
    withLayout: true,
  },
  { path: "/marketplace", component: Marketplace, withLayout: true },
  {
    path: "/marketplaceTransactions",
    component: MarketPlaceTransactions,
    withLayout: true,
  },
  {
    path: "/dataNowWalletTransactions",
    component: DataNowWalletTransactions,
    withLayout: true,
  },
  {
    path: "/flutterwave-webhook",
    component: FlutterwaveWebhook,
    withLayout: true,
  },
  {
    path: "/customers-withdrawal",
    component: CustomerAdminWithdrawal,
    withLayout: true,
  },
  {
    path: "/datanow-admin-transfer",
    component: DataNowAdminTransfer,
    withLayout: true,
  },
  {
    path: "/datanow-data-wallet-transfer",
    component: DataNowWalletTransfer,
    withLayout: true,
  },
  { path: "/", component: SignIn, ispublic: true },
];

export default routes;
