import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "Cable Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (cablePlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'plan_id', value: cablePlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: cablePlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: cablePlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'business_commission_fee', value: cablePlan.business_commission_fee },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'index', value: cablePlan.index },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'source', value: cablePlan.source },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'price', value: cablePlan.price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: cablePlan.message },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'tv', value: cablePlan.tv },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'variation_code', value: cablePlan.variation_code },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  
  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("business_commission_fee", String(formData.business_commission_fee));
    urlencoded.append("source", String(formData.source));
    urlencoded.append("index", String(formData.index));
    urlencoded.append("message", String(formData.message));
    urlencoded.append("tv", String(formData.tv));
    urlencoded.append("price", String(formData.price));
    urlencoded.append("variation_code", String(formData.variation_code));
    

    var requestOptions = {
      method: formData.plan_id ?  "PATCH" : "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    fetch(
      formData.plan_id ?  localStorage.getItem("apiURL") + "cable_settings/"+formData.plan_id  : localStorage.getItem("apiURL") + "cable_settings"  ,

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
   
       formData.plan_id ?  tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "cablePlans", value:tempData.cablePlans.map((cablePlan) => {
            if(data.data.id===cablePlan.id) return {...cablePlan, ...data.data}
            return cablePlan;
           } 
         )}
          }) : 
          fetchCablePlans(1);

        }
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.cablePlansLoaded ) {
      fetchCablePlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'cablePlansLoaded', value: true },
      });
    }
  }, []);
    
  const filterTv =(e)=>{
    var cablePlan=tempData.cablePlansMaster;
   if(e.target.value)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.tv=== e.target.value)
    if(tempData.filterStatus)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.status*1 === tempData.filterStatus*1)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "cablePlans", 
      value: cablePlan
    }});
  }

  
  const filterStatus =(e)=>{
    var cablePlan=tempData.cablePlansMaster;
    if(e.target.value)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.status*1 === e.target.value*1)
    if(tempData.filterTv)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.tv=== tempData.filterTv)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "cablePlans", 
      value: cablePlan
    }});
  }

    
  const CablePlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.cablePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.cablePlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   >{props.cablePlan.tv}</td>
             <td   >{props.cablePlan.name}</td>
             <td   >{props.cablePlan.source}</td>
             <td  >₦{formatCurrency(props.cablePlan.price)}</td>
             <td  >{formatCurrency(props.cablePlan.business_commission_fee)}%</td>
             <td   >{props.cablePlan.variation_code}</td>
             <td   >{props.cablePlan.variation_code}</td>
             <td   >{props.cablePlan.index}</td>
             <td   >{props.cablePlan.message}</td>
             <td   >{props.cablePlan.id}</td>
             <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.cablePlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchCablePlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving Cable plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
              };
              fetch(
                localStorage.getItem("apiURL") + "cable_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                     tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "cablePlans", value: data.data },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "cablePlansMaster", value: data.data },
                    });
                  
                  } else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
          
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Cable Plans</b>
                <b className="text-primary float-right" style={{cursor:'pointer'}} onClick={()=>{
                  planSelect ({id:false,phone_number:'', password:'',tv:'MTN',type:0})
                }}><i className="mdi mdi-plus"></i> Add Cable Plan</b></p>
                <hr className="border-primary" />
                </div>
                 <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={tempData.filterTv} name="filterTv" onChange={(e)=>{formOnChange(e); filterTv(e)} }>
                  <option value="">All TVs</option>
                  <option value="DSTV">DSTV</option>
                  <option value="GOTV">GOTV</option>
                  <option value="STARTIMES">STARTIMES</option>
                  </select>
                  </Col>

                      <Col md="2" xs="6" className="form-group mb-2">
                          <select className="form-control" value={tempData.filterStatus} name="filterStatus"  onChange={(e)=>{formOnChange(e); filterStatus(e)} }>
                          <option value="">All Statuses</option>
                          <option value={0}>Active</option>
                          <option value={1}>Disabled</option>
                         </select>
                        </Col>
                  </Row>
                   
                  {tempData.cablePlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.cablePlans.length} Cable Plans</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      
                      <th data-priority="1">TV Plan Status</th>
                      <th data-priority="1">TV</th>
                      <th data-priority="1">Name</th>
                      <th data-priority="1">Source</th>
                      <th data-priority="1">Price</th>
                      <th data-priority="1">Business Commission</th>
                      <th data-priority="1">Variation Code</th>
                       <th data-priority="1">Index</th>
                       <th data-priority="1">Message</th>
                       <th data-priority="1">ID</th>

                      </tr>
                      </thead>
                      <tbody>
                      { tempData.cablePlans.map((cablePlan) => {
                        return <CablePlan key={cablePlan.id} cablePlan={cablePlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.cablePlans && ( <Modal isOpen={tempData.isModalOpen}  >
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
          <p className="text-uppercase text-left mb-0 " ><b>{formData.plan_id ? 'Edit Cable Plan': 'Add Cable Plan'}</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

          <p className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>TV</b>
                </p>
                  <select className="form-control" value={formData.tv} name="tv" onChange={(e)=>{formOnChange(e);} }>
                  <option value="DSTV">DSTV</option>
                  <option value="GOTV">GOTV</option>
                  <option value="STARTIMES">STARTIMES</option>
                  </select>

                  <p className="  text-left mb-0 mt-2 "
                >
                  <b>Source</b>
                </p>
                  <select className="form-control" value={formData.source} name="source" onChange={(e)=>{formOnChange(e);} }>
                  <option value="DATANOW">DATANOW</option>
                  <option value="GOLAD">GOLAD</option>
                  <option value="SAGECLOUD">SAGECLOUD</option>
                  <option value="VTPASS">VTPASS</option>
                  </select>


                 <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter plan name"
                  type="text"
                 />
                 
                              
 <AvField
      label="Price"
      name="price"
      value={formData.price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Plan Price"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

 <AvField
      label="Business Commission"
      name="business_commission_fee"
      value={formData.business_commission_fee}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Transaction Charge"
      type="text"
      errorMessage="Enter valid amount"
      required
      />


<AvField
      label="Variation Code"
      name="variation_code"
      value={formData.variation_code}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Variation Code"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

                  
<AvField
      label="Plan Index"
      name="index"
      value={formData.index}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Plan index"
      type="text"
      errorMessage="Enter valid amount"
      required
      />

<AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  
                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  {formData.plan_id ? 'Save Changes': 'Add Cable Plan'}
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        