import React, {  } from 'react';
import { Link } from 'react-router-dom';

const MenuItem = (props) =>{
 
  
  const  MenuClick = () => {
        var width = window.innerWidth;
        if (width < 992) {
         }
    }

     const item = props.item || {};
    return (
      <React.Fragment>
            <Link onClick={MenuClick} to={item.url}>
               {item.icon && <i className={item.icon}></i>}
               {item.label} 
            </Link>
      </React.Fragment>
    );
 
}
 

export default MenuItem;

















